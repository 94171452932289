<template>
    <v-container fluid class="grey lighten-4" style="min-height: calc(100vh - 64px)">
        <v-container fluid>
            <v-row>
                <v-col sm="12" md="4" class="d-flex align-center">
                    <v-text-field
                        placeholder="Search"
                        label="Search"
                        outlined
                        v-model="search"
                        clearable                        
                        flat
                        hide-details
                        dense
                        v-on="on"
                ></v-text-field>
                </v-col>
                
                <v-col sm="12" md="4" class="d-flex align-center">
                    
                    <v-menu                        
                        dense                                                
                        :return-value.sync="dates"
                        v-model="dateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        ref="menu"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                placeholder="date filter"
                                label="Date"
                                outlined
                                v-model="dates"
                                clearable
                                readonly
                                v-bind="attrs"
                                flat
                                hide-details
                                dense
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="dates" range no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="dateMenu = false">Cancel</v-btn>
                            <v-btn text color="primary" @click="$refs.menu.save(dates)">OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" md="4" align="end">
                    
                    <v-btn                        
                        :disabled="download.loading"
                        :loading="download.loading"
                        color="primary"
                        @click="downloadData()"
                    >
                        Download
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
             <v-col
                cols="12"
                class="py-2"
                style="overflow-y:auto;"
            >                
                
                <v-btn-toggle
                    v-model="status"                    
                    borderless
                    mandatory
                    background-color="grey lighten-1"
                    
                    
                >                                
                    <v-btn value="-1" small style="min-width:110px">
                        <span class="">All</span>  
                        <span v-if="status == -1">&nbsp;({{totalData}})</span>
                    </v-btn>                
                    <v-btn value="0" small style="min-width:110px">
                        <span class="">Pending</span>    
                        <span v-if="status == 0">&nbsp;({{totalData}})</span>
                    </v-btn>               
                    <v-btn value="1" small style="min-width:110px">
                        <span class="">Successful</span>          
                        <span v-if="status == 1">&nbsp;({{totalData}})</span>
                    </v-btn>
                    <v-btn value="2" small style="min-width:110px">
                        <span class="">Failed</span>          
                        <span v-if="status == 2">&nbsp;({{totalData}})</span>
                    </v-btn>                                          
                    <!-- <v-btn value="3" small style="min-width:110px">
                        <span class="">Cancelled</span>          
                        <span v-if="status == 3">&nbsp;({{totalData}})</span>
                    </v-btn>                                        -->
                </v-btn-toggle>
            </v-col>
            
        </v-row>

            <v-row>
                <v-col cols=12>
                    <v-data-table
                        :footer-props="{itemsPerPageOptions:[25,50,100]}"
                        :headers="headers"
                        :items="dataList"
                        :items-per-page="15"
                        :server-items-length="totalData"
                        :loading="loadingData"
                        :options.sync="options"
                        class="elevation-1"
                    >
                        <template v-slot:body="{ items }" v-if="dataList.length">
                            <tbody>
                            <tr v-for="(item, index) in items" :key="item.id">
                                <td class="text-body-2">
                                    <router-link target="_blank" tag="a" :to="'/orders/'+item.id">{{ item.number }}</router-link>
                                </td>
                                <td>
                                    {{ item.user.first_name +" "+item.user.last_name }}
                                </td>
                                <td>
                                    {{ item.color }}
                                </td>
                                <td>
                                    {{ item.city }}
                                </td>
                                <td>
                                    {{ item.user.mobile }}
                                </td>
                                <td>
                                    {{ item.user.email }}
                                </td>
                               
                                <td>
                                    <order-status :orderDetails="item"/>
                                                                
                                </td>
                                 <td>
                                     <template v-if="status ==3 ">{{ dateutils.toDisplay(item.created_at) }}</template>
                                     <template v-else>{{ dateutils.toDisplay(item.created_at) }}</template>                                    
                                </td>
                            </tr>
                            </tbody>
                        </template>
                        <template v-slot:loading>
                            Loading...
                        </template>
                        <template v-slot:no-data>
                            No Data found
                        </template>

                    </v-data-table>
                </v-col>
            </v-row>

        </v-container>
    </v-container>
</template>

<script>
    import {mapGetters} from 'vuex';
    import OrderStatus from "../../components/OrderStatus.vue";
    export default {
        computed: {
            ...mapGetters([
                'accessToken'
            ])
        },
        components:{
            OrderStatus
        },
        name:"PreBookings",
        mounted(){

            // this.dates.push(this.dateutils.dayjs().startOf('month').format("YYYY-MM-DD"))
            // this.dates.push(this.dateutils.dayjs().format("YYYY-MM-DD"))
            this.status = this.$route.query.status
            this.search = this.$route.query.search
            // this.startDate = this.$route.query.startDate
            // this.endDate = this.$route.query.endDate
            if(this.$route.query.startDate && this.$route.query.endDate){
                this.dates.push(this.$route.query.startDate)
                this.dates.push(this.$route.query.endDate)
            }
            
            this.$watch('search', function(value) {
                if(this.searchDelay) {
                    clearTimeout(this.searchDelay)
                }
                this.searchDelay = setTimeout(() => {
                    this.$router.replace({path: '/orders', query: { status: this.status, search: value, startDate: this.startDate, endDate: this.endDate }})
                    this.getDataList()
                }, 1000)
            })

            this.$watch('status', function(value) {

                this.updateHeaders()
                this.$router.replace({path: '/orders', query: { status: value, search: this.search, startDate: this.startDate, endDate: this.endDate }})
                this.getDataList()
            })

            this.$watch('dates', function(value) {
                if(this.dates == null)
                    this.dates = []

                this.populateDates()
                if(this.dates.length == 0 || this.dates.length==2){
                    this.$router.replace({path: '/orders', query: { status: this.status, search: this.search,  startDate: this.startDate, endDate: this.endDate }})
                    this.getDataList()
                }
            })
            this.updateHeaders()
            this.populateDates()
            
        },
        methods: {
            updateHeaders(){
                var newHeaders = [
                    {text: 'Order No', sortable: false},
                    {text: 'Name', sortable: false},
                    {text: 'Color', sortable: false},
                    {text: 'City', sortable: false},
                    {text: 'Mobile', sortable: false},
                    {text: 'Email', sortable: false},                        
                    {text: 'Status', sortable: false},                
                ]
                if(this.status >=3 && this.status<=5 ){
                    newHeaders.push({text: 'Created At', sortable: false})
                }
                else
                    newHeaders.push({text: 'Created At', sortable: false})
                this.headers = newHeaders
                
            },
            populateDates(){
                if(this.dates.length == 2){

                    if(this.dates[0] > this.dates[1]){
                        this.startDate = this.dates[1]
                        this.endDate = this.dates[0]
                    }
                    else{
                        this.startDate = this.dates[0]
                        this.endDate = this.dates[1]
                    }
                }
                else{
                    this.startDate = ""
                    this.endDate = ""
                }                
            },
            downloadData() {
                this.download.loading = true
                var searchData = this.search
                if(searchData)
                    searchData = searchData.trim()
                Vue.axios({
                    url: '/api/admin/orders/export',                    
                    method: 'GET',
                    responseType: 'blob',
                    params:{
                            start_date:this.startDate,
                            end_date:this.endDate,                            
                            page:this.options.page,
                            rows:this.options.itemsPerPage,
                            sortBy:this.options.sortBy[0],
                            sortDesc:this.options.sortDesc[0],
                            status:this.status,
                            search:searchData
                        }
                })
                .then((response) => {

                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement('a');
                    
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', 'orders.xlsx');
                        document.body.appendChild(fileLink);
                    
                        fileLink.click();
                    
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    this.download.loading = false
                })

            },
            getDataList() {
                this.dataList = [];
                this.loadingData = true;
                this.totalData = 0
                var searchData = this.search
                if(searchData)
                 searchData = searchData.trim()
                Vue.axios.get(`/api/admin/orders`,
                    {
                        params:{
                            start_date:this.startDate,
                            end_date:this.endDate,                            
                            page:this.options.page,
                            rows:this.options.itemsPerPage,
                            sortBy:this.options.sortBy[0],
                            sortDesc:this.options.sortDesc[0],
                            status:this.status,
                            search:searchData
                        }
                    }
                )
                    .then((response) => {

                        var list = [];
                        
                        list = response.data.data.data
                        this.totalData = response.data.data.meta.total

                        list.forEach(element => {
                                element.loading={};
                                element.loading=false;
                            });
                        this.dataList = list
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                    .finally(() => {
                        this.loadingData = false
                    })
            },
        },
            data() {
                return {
                    headers: [
                        {text: 'Order No', sortable: false},
                        {text: 'Name', sortable: false},
                        {text: 'Color', sortable: false},
                        {text: 'City', sortable: false},
                        {text: 'Mobile', sortable: false},
                        {text: 'Email', sortable: false},                        
                        {text: 'Status', sortable: false},
                        {text: 'Created At', sortable: false},
                    ],
                    totalData: 0,
                    loadingData: true,
                    dataList:[],
                    options: {},
                    dates:[],
                    startDate:"",
                    endDate:"",
                    dateMenu: false,
                    status:"-1",
                    search:"",
                    searchDelay: null,
                    download:{
                        loading:false
                    }
                }
            },

            watch:{
              
                options: {
                    handler () {
                        this.getDataList()
                    },
                    deep: true,
                }
            }
    }

</script>

<style scoped>

</style>
