<template>
   <v-container fluid class="grey lighten-4" style="min-height: calc(100vh - 64px)">
      <div style="height:90vh;" class="d-flex justify-center align-center" v-if="pageLoading">
         <v-progress-circular
            indeterminate
            color="primary"
         ></v-progress-circular>
      </div>

      <v-container fluid v-else>
         <h2 class="mb-5">Party Master</h2>
         <v-card elevation="0" class="mb-6">
            <h4 class="px-4 py-3"> Details </h4>
            <v-row class="px-4">
               <v-col cols="12" md="6" lg="4">
                  <h6 class="font-weight-bold">Name</h6>
                  <p class="mb-0" >{{this.partyMaster.name ? this.partyMaster.name : '-'}}</p>
               </v-col>
               <v-col cols="12" md="6" lg="4">
                  <h6 class="font-weight-bold">Type</h6>
                  <p class="mb-0">{{this.partyMaster.type ? this.partyMaster.type : '-'}}</p>
               </v-col>
               <!-- <v-col cols="12" md="6" lg="4">
                  <h6 class="font-weight-bold">Facility</h6>
                  <p class="mb-0">{{this.partyMaster.facility ? this.partyMaster.facility : '-'}}</p>
               </v-col> -->
               <v-col cols="12" md="6" lg="4">
                  <h6 class="font-weight-bold">Facility</h6>
                  <!-- <p class="mb-0">{{this.partyMaster.pack_type ? this.partyMaster.pack_type : '-'}}</p> -->
                  <div v-if="this.partyMaster.facility.length > 0">
                     <v-chip-group
                        active-class="primary--text"
                        column
                     >
                        <v-chip
                           v-for="(tag, i) in this.partyMaster.facility"
                           :key="i"
                        >
                           {{ tag.name }}
                        </v-chip>
                     </v-chip-group>
                  </div>
                  <div v-else> - </div>
               </v-col>
               <v-col cols="12">
                  <h6 class="font-weight-bold">Address</h6>
                  <p class="mb-0">{{this.partyMaster.address ? this.partyMaster.address : '-'}}</p>
               </v-col>
               <v-col cols="12" md="6" lg="4">
                  <h6 class="font-weight-bold">Country</h6>
                  <p class="mb-0">{{this.partyMaster.country ? this.partyMaster.country : '-'}}</p>
               </v-col>
               <v-col cols="12" md="6" lg="4">
                  <h6 class="font-weight-bold">State</h6>
                  <!-- <p class="mb-0">{{this.partyMaster.state.name ? this.partyMaster.state.name : '-'}}</p> -->
                  <p class="mb-0" v-if="this.partyMaster.state">{{ this.partyMaster.state.name}}</p>
                  <p class="mb-0" v-else> - </p>
               </v-col>
               <v-col cols="12" md="6" lg="4">
                  <h6 class="font-weight-bold">City</h6>
                  <!-- <p class="mb-0">{{this.partyMaster.city.name ? this.partyMaster.city.name : '-'}}</p> -->
                  <p class="mb-0" v-if="this.partyMaster.city">{{ this.partyMaster.city.name}}</p>
                  <p class="mb-0" v-else> - </p>
               </v-col>
               <v-col cols="12" md="6" lg="4">
                  <h6 class="font-weight-bold">Pack Type</h6>
                  <!-- <p class="mb-0">{{this.partyMaster.pack_type ? this.partyMaster.pack_type : '-'}}</p> -->
                  <div v-if="this.partyMaster.pack_type.length > 0">
                     <v-chip-group
                        active-class="primary--text"
                        column
                     >
                        <v-chip
                           v-for="(tag, i) in this.partyMaster.pack_type"
                           :key="i"
                        >
                           {{ tag.name }}
                        </v-chip>
                     </v-chip-group>
                  </div>
                  <div v-else> - </div>
               </v-col>
               <v-col cols="12" md="6" lg="4">
                  <h6 class="font-weight-bold">Dosage Form</h6>
                  <!-- <p class="mb-0">{{this.partyMaster.dosage_form ? this.partyMaster.dosage_form : '-'}}</p> -->
                  <div v-if="this.partyMaster.dosage_form.length > 0">
                     <v-chip-group
                        active-class="primary--text"
                        column
                     >
                        <v-chip
                           v-for="(tag, i) in this.partyMaster.dosage_form"
                           :key="i"
                        >
                           {{ tag.name }}
                        </v-chip>
                     </v-chip-group>
                  </div>
                  <div v-else> - </div>
               </v-col>
               <v-col cols="12" md="6" lg="4">
                  <h6 class="font-weight-bold">Category</h6>
                  <!-- <p class="mb-0">{{this.partyMaster.category ? this.partyMaster.category : '-'}}</p> -->
                  <div v-if="this.partyMaster.category.length > 0">
                     <v-chip-group
                        active-class="primary--text"
                        column
                     >
                        <v-chip
                           v-for="(tag, i) in this.partyMaster.category"
                           :key="i"
                        >
                           {{ tag.name }}
                        </v-chip>
                     </v-chip-group>
                  </div>
                  <div v-else> - </div>
               </v-col>
               <!-- <v-col cols="12" md="6" lg="4">
                  <h6 class="font-weight-bold">Packing</h6>
                  <p class="mb-0">{{this.partyMaster.packing ? this.partyMaster.packing : '-'}}</p>
               </v-col> -->
               <v-col cols="12" md="6" lg="4">
                  <h6 class="font-weight-bold">Packing</h6>
                  <div v-if="this.partyMaster.packing.length > 0">
                     <v-chip-group
                        active-class="primary--text"
                        column
                     >
                        <v-chip
                           v-for="(tag, i) in this.partyMaster.packing"
                           :key="i"
                        >
                           {{ tag.name }}
                        </v-chip>
                     </v-chip-group>
                  </div>
                  <div v-else> - </div>
               </v-col>
               <!-- <v-col cols="12" md="6" lg="4">
                  <h6 class="font-weight-bold">Certificate</h6>
                  <p class="mb-0">{{this.partyMaster.certificate ? this.partyMaster.certificate : '-'}}</p>
               </v-col> -->
               <v-col cols="12" md="6" lg="4">
                  <h6 class="font-weight-bold">Certificate</h6>
                  <div v-if="this.partyMaster.certificate.length > 0">
                     <v-chip-group
                        active-class="primary--text"
                        column
                     >
                        <v-chip
                           v-for="(tag, i) in this.partyMaster.certificate"
                           :key="i"
                        >
                           {{ tag.name }}
                        </v-chip>
                     </v-chip-group>
                  </div>
                  <div v-else> - </div>
               </v-col>
               <v-col cols="12" md="6" lg="4">
                  <h6 class="font-weight-bold">Authorised</h6>
                  <!-- <p class="mb-0">{{this.partyMaster.authorised ? this.partyMaster.authorised : '-'}}</p> -->
                  <div v-if="this.partyMaster.authorised.length > 0">
                     <v-chip-group
                        active-class="primary--text"
                        column
                     >
                        <v-chip
                           v-for="(tag, i) in this.partyMaster.authorised"
                           :key="i"
                        >
                           {{ tag.name }}
                        </v-chip>
                     </v-chip-group>
                  </div>
                  <div v-else> - </div>
               </v-col>
               <v-col cols="12">
                  <h6 class="font-weight-bold">Description</h6>
                  <p class="mb-0">{{this.partyMaster.note ? this.partyMaster.note : '-'}}</p>
               </v-col>
            </v-row>
         </v-card>

         <v-card elevation="0" class="mb-6">
            <h4 class="px-4 py-3"> Contacts </h4>
            <v-data-table
               :headers="contactHeaders"
               :items="contacts"
               class="elevation-0"
               hide-default-footer>

               <template v-slot:[`item.email`]="{ item }">
                  {{ item.email ?  item.email : '-'}}
               </template>

               <template v-slot:[`item.phone`]="{ item }">
                  {{ item.phone ?  item.phone : '-'}}
               </template>
            </v-data-table>
         </v-card>

         <v-card elevation="0">
            <h4 class="px-4 py-3"> Products </h4>
            <v-data-table
               class="elevation-0"
               hide-default-footer
               :headers="productMasterHeaders"
               :items="productMasters"
            >

            <template v-slot:[`item.product_name`]="{ item }">
               {{ item.product_name ?  item.product_name : '-'}}
            </template>

            <template v-slot:[`item.molecule`]="{ item }">
               <!-- {{ item.molecule.name ?  item.molecule.name : '-'}} -->
               <div v-if="item.molecule">{{ item.molecule.name }}</div>
               <div v-else> - </div>
            </template>

            <template v-slot:[`item.company`]="{ item }">
               <!-- {{ item.company.name ?  item.company.name : '-'}} -->
               <div v-if="item.company">{{ item.company.name }}</div>
               <div v-else> - </div>
            </template>

            <template v-slot:[`item.product_category`]="{ item }">
               <div v-if="item.product_category.length > 0">
                     <v-chip-group
                        active-class="primary--text"
                        column
                     >
                        <v-chip
                           v-for="(tag, i) in item.product_category"
                           :key="i"
                        >
                           {{ tag.name }}
                        </v-chip>
                     </v-chip-group>
                  </div>
                  <div v-else> - </div>
            </template>

            <template v-slot:[`item.product_strength`]="{ item }">
               <div v-if="item.product_strength.length > 0">
                     <v-chip-group
                        active-class="primary--text"
                        column
                     >
                        <v-chip
                           v-for="(tag, i) in item.product_strength"
                           :key="i"
                        >
                           {{ tag.name }}
                        </v-chip>
                     </v-chip-group>
                  </div>
                  <div v-else> - </div>
            </template>

            <template v-slot:[`item.product_dosage_form`]="{ item }">
               <div v-if="item.product_dosage_form.length > 0">
                     <v-chip-group
                        active-class="primary--text"
                        column
                     >
                        <v-chip
                           v-for="(tag, i) in item.product_dosage_form"
                           :key="i"
                        >
                           {{ tag.name }}
                        </v-chip>
                     </v-chip-group>
                  </div>
                  <div v-else> - </div>
            </template>

            <template v-slot:[`item.action`]="{ item }">
               <v-btn @click="showProductDetails(item)">OPEN</v-btn>
            </template>

            </v-data-table>
         </v-card>

      </v-container>

      
   </v-container>
</template>

<script>
  export default {
    data () {
      return {
         pageLoading:false,
        contactHeaders: [
          {
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Email', value: 'email' },
          { text: 'Phone Number', value: 'phone' },
        ],
        partyMaster: {
            name: "",
            address: "",
            type: '',
            facility: [],
            country: '',
            state: '',
            city: '',
            pack_type: [],
            dosage_form: [],
            category: [],
            packing: [],
            certificate: [],
            authorised: [],
            note: ''
        },
        contacts: [],
        productMasterHeaders: [
           { text: 'Name', value: 'product_name' },
            {
               text: 'Molecule',
               align: 'start',
               sortable: false,
               value: 'molecule',
            },
            { text: 'Strength', value: 'product_strength' },
            { text: 'Dosage Form', value: 'product_dosage_form' },
            { text: 'Company', value: 'company' },
            { text: 'Category', value: 'product_category' },
            { text: "", sortable: false, value: "action" }
         ],
         productMasters: []
      }
    },

    created () {
      this.loadDetails()
   },

    methods: {
      loadDetails() {
         this.pageLoading = true
         Vue.axios
            .post(`/api/show`,{id: Number(this.$route.params.id)})
            .then((res) => {
               const data = res.data.data
               
               this.partyMaster.name = data.name
               this.partyMaster.address = data.address
               this.partyMaster.type = data.type
               this.partyMaster.facility = data.facility
               this.partyMaster.country = data.country
               this.partyMaster.state = data.state
               this.partyMaster.city = data.city
               this.partyMaster.pack_type = data.pack_type
               this.partyMaster.dosage_form = data.dosage_form
               this.partyMaster.category = data.category
               this.partyMaster.packing = data.packing
               this.partyMaster.certificate = data.certificate
               this.partyMaster.authorised = data.authorised
               this.partyMaster.note = data.note
               this.contacts = data.contact
               this.productMasters = data.product_master
            })
            .catch((error) => {
               console.log(error);
            })
            .finally(() => {
               this.pageLoading = false
            });
      },

      showProductDetails(obj) {
         window.open("/productDetails/" + obj.id, '_blank', 'noreferrer');
      },
    },

    

  }
</script>

<style>

</style>