<template>
    <v-container
        fluid
        class="grey lighten-4"
        style="min-height: calc(100vh - 64px)"
    >
    <div style="height:90vh;" class="d-flex justify-center align-center" v-if="pageLoading">
            <v-progress-circular
            :size="40"
            :width="3"
            color="purple"
            indeterminate
        ></v-progress-circular>
        </div>
        <v-container v-else fluid>
           
            <v-row>
            
            <v-col cols="12">
                    <user-component :userDetails="userDetails" />
                </v-col>
                
            </v-row>
  
            <v-row>
                <v-col cols=12>
                    <v-data-table
                        :footer-props="{itemsPerPageOptions:[25,50,100]}"
                        :headers="headers"
                        :items="dataList"                                                
                        class="elevation-1"
                        
                    >
                        <template v-slot:body="{ items }" v-if="dataList.length">
                            <tbody>
                            <tr v-for="(item, index) in items" :key="item.id">
                                <td class="text-body-2">
                                    <router-link tag="a" :to="'/orders/'+item.id">{{ item.number }}</router-link>
                                </td>
                                <td>
                                    {{ item.color }}
                                </td>
                                <td>
                                    {{item.state}} / {{item.city}} / {{item.pincode}}
                                </td>                                                                                               
                                <td>
                                    <order-status :orderDetails="item" />
                                </td>
                                 <td>
                                    {{ dateutils.toDisplay(item.created_at) }}
                                </td>
                            </tr>
                            </tbody>
                        </template>
                        <template v-slot:loading>
                            Loading...
                        </template>
                        <template v-slot:no-data>
                            No Data found
                        </template>

                    </v-data-table>
                </v-col>
            </v-row>

          

            
        </v-container>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import OrderStatus from "../../components/OrderStatus.vue";
import UserComponent from "../../components/UserComponent.vue";

export default {
    computed: {
        ...mapGetters(["accessToken"]),
    },
    components:{
        OrderStatus,
        UserComponent
    },
    name: "UserDetails",
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.pageLoading = true
            Vue.axios
                .get(`/api/admin/users/${this.$route.params.id}`)
                .then((response) => {
                    if (response.data.status == 1) {
                        this.userDetails = response.data.data;
                        this.orderList = response.data.data.orders;
                        this.totalData = this.orderList.length
                        this.dataList = this.orderList
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.pageLoading = false
                });
        },
    },
    data() {
        return {     
            pageLoading:false,       
            userDetails:{},
            orderList:[],
            headers: [
                {text: 'Order No', sortable: false},
                {text: 'Color', sortable: false},
                {text: 'Location', sortable: false},                
                {text: 'Status', sortable: false},
                {text: 'Created At', sortable: false},
            ],
            totalData: 0,
            loadingData: true,
            dataList:[],
            options: {},
        };
    },

    watch: {},
};
</script>
