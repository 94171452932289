import dayjs from 'dayjs'

var customParseFormat = require('dayjs/plugin/customParseFormat')

dayjs.extend(customParseFormat)

function toDisplay(datetime){
    return dayjs(datetime, "YYYY-MM-DD HH:mm:ss").format('ddd, D MMM YYYY, hh:mm a');   
}

function toDisplayDate(datetime){
    return dayjs(datetime, "YYYY-MM-DD").format('ddd, D MMM YYYY');   
}

function toLocal(datetime){    
    return dayjs(datetime, "YYYY-MM-DD HH:mm:ss")
}


export {dayjs, toDisplay, toLocal, toDisplayDate};