<template>
    <v-container
        fluid
        class="grey lighten-4"
        style="min-height: calc(100vh - 64px)"
    >
         <div style="height:90vh;" class="d-flex justify-center align-center" v-if="pageLoading">
            <v-progress-circular
                :size="40"
                :width="3"
                color="purple"
                indeterminate
            ></v-progress-circular>
        </div>
        <v-container fluid v-else>
            
            <v-row v-if="refundDetails">
                <v-col cols="12">
                    <refund-component :refundDetails="refundDetails" />
                </v-col>                
            </v-row>

            <v-row>
                <v-col cols="12">
                    <Order :orderDetails="orderDetails" 
                        @get_data="getData"
                    />
                </v-col>
            </v-row>                        
                        
            <v-row>
                <v-col cols="12">
                    <user-component :userDetails="userDetails" />
                </v-col>
                
            </v-row>
        </v-container>
            
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import OrderStatus from "../../components/OrderStatus.vue";
import Order from "../../components/Order.vue";
import UserComponent from '../../components/UserComponent.vue';
import RefundComponent from '../../components/RefundComponent.vue';

export default {
    computed: {
        ...mapGetters(["accessToken"]),
    },
    components:{
        OrderStatus,
        Order,
        UserComponent,
        RefundComponent
    },
    name: "PreBookingDetails",
    mounted() {
        this.getData();
    },
    methods: {
        
        getData() {
            this.pageLoading = true
            Vue.axios
                .get(`/api/admin/refunds/${this.$route.params.id}`)
                .then((response) => {
                    if (response.data.status == 1) {
                        this.refundDetails = response.data.data;
                        this.orderDetails = this.refundDetails.order;
                        this.userDetails = this.orderDetails.user;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.pageLoading = false
                });
        },
    },
    data() {
        return {                                    
            pageLoading:false,
            orderDetails: {},
            refundDetails:{},
            userDetails:{}            
        };
    },

    watch: {},
};
</script>
