<template>
    <v-container fluid class="grey lighten-4" style="min-height: 100vh">
        <div style="height:90vh;" class="d-flex justify-center align-center" v-if="pageLoading">
            <v-progress-circular
        :size="40"
        :width="3"
        color="purple"
        indeterminate
        ></v-progress-circular>
        </div>
        <div v-else>
            <ValidationObserver v-slot="{ invalid, handleSubmit }">
        <v-form @submit.prevent="handleSubmit(submitFaq)">
            <v-container fluid>
                <v-row>

                    <v-col cols="12" sm="12" md="4" class="pb-0">
                        <ValidationProvider name="category" rules="required" v-slot="{ errors }">
                        <v-select 
                            dense 
                            :items="allCategories" 
                            item-text="name" 
                            item-value="id" 
                            label="Category" 
                            outlined 
                            name="category"                         
                            v-model="faqDetails.category_id"
                            :error-messages="errors"
                        >
                        </v-select>
                        </ValidationProvider>
                    </v-col>

                    <v-col cols="12" sm="12" md="4" class="pb-0">
                        <ValidationProvider name="sub_category" rules="required" v-slot="{ errors }">
                        <v-select 
                            dense 
                            :items="uiSubCategories" 
                            item-text="name" 
                            item-value="id" 
                            label="Sub Category" 
                            outlined 
                            name="sub_category"                         
                            v-model="faqDetails.sub_category_id"
                            :error-messages="errors"
                        >
                        </v-select>
                        </ValidationProvider>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" md="8" class="pb-0">
                        <ValidationProvider name="question" rules="required" v-slot="{ errors }">
                        <v-text-field                    
                            name="question"
                            v-model="faqDetails.question"                                                
                            label="Question"
                            outlined
                            dense
                            required
                            :error-messages="errors"
                        ></v-text-field>
                        </ValidationProvider>
                    </v-col>
                </v-row>
                <v-row>
                     
                    <v-col cols="12" sm="12" md="8" class="pb-0">
                        <ValidationProvider name="answer" rules="required" v-slot="{ errors }">
                            <vue-editor name="answer" v-model="faqDetails.answer"
    :editorToolbar="customToolbar"
                            ></vue-editor>
                            <div v-if="errors.length">{{errors[0]}}</div>
                        </ValidationProvider>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" md="8" class="mt-2">
                        <v-btn type="submit" class="primary"
                            :disabled="submitLoading"
                            :loading="submitLoading"
                        >
                            <template v-if="$route.meta.isUpdate"> Update </template>
                            <template v-else> Create </template>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>                
        </v-form>
        </ValidationObserver>
        </div>
    </v-container>
</template>

<script>
    import {mapGetters} from 'vuex'
    import { VueEditor } from "vue2-editor";

    export default {
        name: "CreateFAQ",
         components: {
            VueEditor
        },
        computed: {
            ...mapGetters([
                'userInfo'
            ])
        },
        data() {
            return {                
                customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["link"]],
                faqDetails:{
                    category_id:"",
                    sub_category_id:"",
                    question:"",
                    answer:"",
                    order:null
                },
                allCategories:[],
                uiCategories:[],
                uiSubCategories:[],
                pageLoading:false,
                submitLoading:false

            }
        },
         watch: {        
            'faqDetails.category_id':{
                handler: function () {                    
                    this.updateSubCategoryList()
                },
                deep: true  
                
            }            
        },
        
        mounted(){
            this.getCategories()
            if(this.$route.meta.isUpdate){
                this.getFaqDetails()
            }
            
        },
        methods:{
            getFaqDetails(){
                Vue.axios.get(`/api/admin/faqs/${this.$route.params.id}`)
                    .then((response) => {

                        if(response.data.status == 1){
                            var faq = response.data.data
                            
                            var obj = faq
                            
                            obj.category_id = faq.subCategory.category.id
                            obj.sub_category_id = faq.sub_category_id
                            
                            this.faqDetails = obj
                            
                        }                            
                                                                                
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                    .finally(() => {
                        this.pageLoading = false
                    })
            },
            submitFaq(){
                this.submitLoading = true
                
                var url = ""
                var faqId=""
                if(this.$route.meta.isUpdate){
                    faqId = this.$route.params.id
                    url = "/api/admin/faqs/"+faqId                    
                }                    
                else
                    url = "/api/admin/faqs"

                Vue.axios.post(url,{                    
                    category_id:this.faqDetails.category_id,
                    sub_category_id:this.faqDetails.sub_category_id,
                    question:this.faqDetails.question,
                    answer:this.faqDetails.answer
                })
                    .then((response) => {

                        if(response.data.status == 1)
                            this.$router.replace({path:"/faqs"})
                        else if(response.data.status == 0){
                            this.$store.commit('showSnackbar', {message: response.data.message})
                        }
                                                                                
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                    .finally(() => {
                        this.submitLoading = false
                    })
            },

            updateSubCategoryList(){
                
                var ctx = this
                if(!this.allCategories.length)
                    return
                var cat = this.allCategories.find(function(item){
                    if(item.id == ctx.faqDetails.category_id)
                        return true
                    else
                        return false
                    
                })                
                this.uiSubCategories = cat.subCategories
            },

            getCategories() {                
                this.pageLoading = true;
                Vue.axios.get(`/api/admin/faqs/categories`)
                    .then((response) => {

                        var list = [];
                        this.allCategories = response.data.data
                        this.uiCategories = response.data.data                        

                        list.forEach(element => {
                                element.loading={};
                                element.loading=false;
                            });
                        this.dataList = list
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                    .finally(() => {
                        this.pageLoading = false
                    })
            },
            selectCategory(category){
                
            }
            
        }
          
    }
</script>
