<template>
  <v-app class="grey">
    <v-navigation-drawer
      app
      permanent
      prominent
      :mini-variant="mini"
      v-model="drawer"
      style="background-color: #323232;"
      v-if="shouldShowNav()"
    >

      <v-list dense shaped>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <!-- <div>
              <img :src="getAssetPath('img/logo.png')" class="mr-2" />
            </div> -->
          </v-list-item-avatar>
          <v-list-item-title class="white--text subtitle-1 py-1">Product</v-list-item-title>
        </v-list-item>
        <div v-for="(link, i) in filteredNavList" :key="i">
          <v-list-item :to="link.url" class="v-list-item py-1" :active-class="`#3a3a3a white--text`">
            <v-list-item-icon>
              <v-icon class="white--text">{{ link.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title class="white--text" >{{ link.name }}</v-list-item-title>
          </v-list-item>
        </div>
      </v-list>
      <template v-slot:append>
        <v-divider></v-divider>
        <v-list-item @click="logout()" dense class="white--text">
          <v-list-item-action>
            <v-icon class="white--text">mdi-exit-to-app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Logout
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>
    <v-main>
      <v-app-bar v-if="shouldShowNav()">
        <v-app-bar-nav-icon @click.stop="mini = !mini"></v-app-bar-nav-icon>
        <div class="text-h6 px-2">{{ $route.meta.title }}</div>
      </v-app-bar>
      <router-view :key="$route.path"></router-view>
    </v-main>
    <v-snackbar top right v-model="showSnackbar" :color="snackbar.color">
      {{ snackbar.message }}
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["snackbar", "accessToken"]),

    filteredNavList() {
      return this.navList.filter((navItem) =>  {
        if(navItem.permission === undefined) {
          return true
        } else {
          return this.hasPermission(navItem.permission)
        }
      })
    }
   
  },
  data: () => ({
    mini: false,
    showSnackbar: false,
    drawer: null,
    navList: [
      {
        icon: "mdi-view-dashboard",
        name: "Dashboard",
        url: "/dashboard",
      },
      {
        icon: "mdi-medical-bag",
        name: "Party Master",
        url: "/partyMaster",
        permission: 'party-masters-create'
      },
      {
        icon: "mdi-needle",
        name: "Product Master",
        url: "/productMaster",
        permission: 'product-masters-create'
      },
      {
        icon: "mdi-playlist-plus",
        name: "Link/Unlink Product",
        url: "/addProduct",
        permission: 'party-master-product-master-link-attach'
      },
    ],
  }),

  methods: {
    shouldShowNav() {
      return this.$route.meta.requireLogin;
      
    },
    logout() {
      this.$store.dispatch("userLogout");
      this.$router.replace("/");
    },

    // getUserType() {
    //   if(this.userPermissions.includes('party-master-product-master-link-attach')) {
    //     this.userType = 'admin'
    //   } else if(this.userPermissions.includes('party-masters-update-owner-temp')) {
    //     this.userType = 'regular'
    //   } else {
    //     this.userType = 'viewer'
    //   }
    // }

    
  },

  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "showSnackbar") {
        this.snackbar.message = state.snackbar.message;
        this.snackbar.color = state.snackbar.color;
        this.showSnackbar = true;
      }
    });
  }       
};
</script>

<style scoped>
.v-application--is-ltr
  .v-list--dense.v-list--nav
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding: 0 8px;
}

.nav--links--text >>> .mdi::before {
  color: white;
}


</style>



