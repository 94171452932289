<template>
    <v-card>                        
        <v-card-title>
            Customer Details
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col
                    cols="12"
                    md="4"
                    lg="3"
                >
                    <span class="overline">Name</span><br>
                    <span class="subtitle-1  black--text">{{
                        userDetails.first_name
                    }} {{
                        userDetails.last_name
                    }} </span>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                    lg="3"
                    class=""
                >
                    <span class="overline">Email</span><br>
                    <span class="subtitle-1  black--text">{{
                        userDetails.email
                    }}</span>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                    lg="3"
                    class=""
                >
                    <span class="overline">Mobile</span><br>
                    <span class="subtitle-1  black--text">{{
                        userDetails.mobile
                    }} </span>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                    lg="3"
                    class=""
                >
                    <span class="overline">Signup Source</span><br>
                    <span class="subtitle-1 black--text">{{
                        userDetails.signup_source
                    }} </span>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "UserComponent",
        props: ['userDetails'],
        
        mounted() {
            
        }
    }
</script>
