<template>
    <v-container fluid class="grey lighten-4" style="min-height: calc(100vh - 64px)">
        <v-container fluid>
            <v-data-table
                :server-items-length="totalData"
                :footer-props="{'disable-items-per-page':true}"                                                            
                                                                    
                :options.sync="options"                
                :headers="headers"
                :items="partyMasters"
                class="elevation-0 table"
                :loading="loading"
                style="background-color: transparent;"
                

            >
                <template v-slot:top>
                    <v-toolbar flat class="mb-6 table-toolbar" style="background-color: transparent;">

                        <v-row>
                            <v-col sm="12" md="4" class="d-flex align-center">
                                <!-- <v-text-field
                                    placeholder="Search"
                                    label="Search"
                                    outlined
                                    clearable                        
                                    flat
                                    hide-details
                                    dense
                                ></v-text-field> -->
                            </v-col>
                
                            <v-col cols="12" md="4" class="d-flex align-center">
                                <!-- <v-menu
                                    ref="menu"
                                    v-model="dateMenu"
                                    :close-on-content-click="false"
                                    :return-value.sync="date"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="date"
                                            label="Date"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            outlined
                                            dense
                                            hide-details=""
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date"
                                        no-title
                                        scrollable
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="menu = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.menu.save(date)"
                                    >
                                        OK
                                    </v-btn>
                                    </v-date-picker>
                                </v-menu> -->
                                </v-col>
                
                                <v-col cols="12" md="4"  class="d-flex align-center justify-end">
                                    <v-dialog v-model="deleteDialog.show" max-width="500px" transition="dialog-transition">
                                        <v-card>
                                        <v-card-title class="text-h5 d-block text-center mx-auto">Are you sure you want to delete?</v-card-title>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn @click="deleteDialog.show = false" :disabled="deleteDialog.loading">Cancel</v-btn>
                                            <v-btn @click="deleteItemConfirm()" color="primary" :disabled="deleteDialog.loading" :loading="deleteDialog.loading">Yes</v-btn>
                                            <!-- <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                                            <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn> -->
                                            <v-spacer></v-spacer>
                                        </v-card-actions>
                                        </v-card>
                                    </v-dialog>

                                    <v-btn color="primary mr-4" @click="$router.push({path: `/createPartyMaster`})" v-if="hasPermission('party-masters-create')">
                                        Create
                                    </v-btn>
                                    
                                    <!-- <v-btn color="primary">
                                        Download
                                    </v-btn> -->
                                </v-col>
                        </v-row>
                    



                    
                    </v-toolbar>
                </template>

                <!-- actions start -->
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="editItem(item)"
                        v-if="hasPermission('party-masters-update-owner-temp') || hasPermission('party-masters-update')"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        @click="deleteItem(item)"
                        v-if="hasPermission('party-masters-delete-owner-temp') || hasPermission('party-masters-delete')"
                    >
                        mdi-delete
                    </v-icon>
                </template>
                <!-- actions end -->
                <template v-slot:no-data>
                    <p class="my-3">No data Available</p>
                </template>
            </v-data-table>
        </v-container>
    </v-container>
</template>

<script>

export default {
  data: () => ({
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    dateMenu: false,
    dialog: false,
    dialogDelete: false,    
    loading: true,    
    totalData:0,
    // itemCreatedAt: null,
    // shouldBeVisible: null,
    deleteDialog:{
        show:false,
        loading:false,
        data:null
    },
    options:{
        itemsPerPage: 15,
        page: 1
    },
    headers: [
      {
        text: 'Party Name',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      { text: 'Address', value: 'address' },
      { text: 'Type', value: 'type'},
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    partyId: '',
    partyMasters: [],
    editedIndex: -1,
    editedItem: {
        name: '',
        address: '',
        type: '',
    },
    defaultItem: {
        name: '',
        address: '',
        type: '',
    },
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },

  watch: {
    options: {
        handler () {
            this.getpartyMasters()
        },
        deep: true,
    },
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },

  mounted() {
    this.getpartyMasters()
  },

  created () {
    
  },

  methods: {
    getpartyMasters() {
        this.loading = true
        this.partyMasters= []
        Vue.axios.post(`/api/party-master-list`,{
            page:this.options.page
        })
            .then((res) =>{
                this.loading = false;
                const data = res.data.data.data
                this.partyMasters = data
                this.totalData = res.data.data.total
                this.itemCreatedAt = res.data.data.data
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                this.loading = false
            })
    },

    // editItem (item) {
    //   this.editedIndex = this.partyMasters.indexOf(item)
    //   this.editedItem = Object.assign({}, item)
    // //   this.dialog = true
    // //   this.$router.push('/createPartyMaster')
    // },

    editItem(obj) {
        this.$router.push({path: "/partyMaster/Edit/" + obj.id})
    },

    deleteItem(item){
        this.deleteDialog.data = item
        this.deleteDialog.show = true
    },

    // has24HrsPassed() {
        // this.itemCreatedAt.forEach((item, index) => {
        //     let createdTime = new Date(item.created_at).getTime()
        //     const currentTime = new Date().getTime();

        //     if ((currentTime - createdTime) >= (24 * 60 * 60 * 1000)) {
        //         // this.shouldBeVisible = true
        //     } else {
        //         console.log(index, this.shouldBeVisible)
        //     }
            
        // })
    // },

    deleteItemConfirm () {
    
        this.deleteDialog.loading = true;
        var id = this.deleteDialog.data.id

        Vue.axios.post(`api/delete?id=${id}`)
            .then((res) => {
                this.getpartyMasters()
                this.deleteDialog.show = false
                // this.$store.commit('showSnackbar', {message: res.message})
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                this.deleteDialog.loading = false
            })

    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.partyMasters[this.editedIndex], this.editedItem)
      } else {
        this.partyMasters.push(this.editedItem)
      }
      this.close()
    },
  },
}
</script>

<style scoped>
    .v-data-table >>> .v-data-table__wrapper,
    .v-data-table >>> .v-data-footer {
        background-color: #fff;
    }

    .table-toolbar >>> .v-toolbar__content{
        padding-inline: 0;
    }

    .table >>> tr th {
        white-space: nowrap;
    }
    .number-item:not(:last-child){
        margin-bottom: 1rem;
    }

    .v-expansion-panel--active>.v-expansion-panel-header{
        min-height: 3rem;
    }

    .contact-list-item{
        min-height: 1.5rem;
    }
</style>

