var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"grey lighten-4",staticStyle:{"min-height":"100vh"},attrs:{"fluid":""}},[(_vm.pageLoading)?_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"height":"90vh"}},[_c('v-progress-circular',{attrs:{"size":40,"width":3,"color":"purple","indeterminate":""}})],1):_c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitFaq)}}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dense":"","items":_vm.allCategories,"item-text":"name","item-value":"id","label":"Category","outlined":"","name":"category","error-messages":errors},model:{value:(_vm.faqDetails.category_id),callback:function ($$v) {_vm.$set(_vm.faqDetails, "category_id", $$v)},expression:"faqDetails.category_id"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"sub_category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dense":"","items":_vm.uiSubCategories,"item-text":"name","item-value":"id","label":"Sub Category","outlined":"","name":"sub_category","error-messages":errors},model:{value:(_vm.faqDetails.sub_category_id),callback:function ($$v) {_vm.$set(_vm.faqDetails, "sub_category_id", $$v)},expression:"faqDetails.sub_category_id"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"question","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"question","label":"Question","outlined":"","dense":"","required":"","error-messages":errors},model:{value:(_vm.faqDetails.question),callback:function ($$v) {_vm.$set(_vm.faqDetails, "question", $$v)},expression:"faqDetails.question"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"answer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-editor',{attrs:{"name":"answer","editorToolbar":_vm.customToolbar},model:{value:(_vm.faqDetails.answer),callback:function ($$v) {_vm.$set(_vm.faqDetails, "answer", $$v)},expression:"faqDetails.answer"}}),(errors.length)?_c('div',[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12","sm":"12","md":"8"}},[_c('v-btn',{staticClass:"primary",attrs:{"type":"submit","disabled":_vm.submitLoading,"loading":_vm.submitLoading}},[(_vm.$route.meta.isUpdate)?[_vm._v(" Update ")]:[_vm._v(" Create ")]],2)],1)],1)],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }