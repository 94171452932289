import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);

export default new Vuex.Store({
    state: {        
        accessToken: '',
        user: '',        
        snackbar: {color: '', message: ''},
        userPermissions: [],
    },
    getters: {
        accessToken: state => {
            return state.accessToken
        },
        user: state => {
            return state.user
        },        
        snackbar: state => {
            return state.snackbar
        },
        permissions: state => {
            return state.userPermissions
        }
        
    },
    mutations: {
        storeAccessToken(state, accessToken) {
            state.accessToken = accessToken;
        },
        storeUser(state, user) {
            state.user = user;
        },                
        removeAccessToken(state) {
            state.accessToken = null;
        },
        removeUser(state) {
            state.user = null;
        },                
        showSnackbar(state, snackbar) {
            state.snackbar.message = snackbar.message
            state.snackbar.color = snackbar.color
        },
        storeUserPermissions(state, permissions) {
            state.userPermissions = permissions
        }
    },
    actions: {
        storeToken({commit, dispatch}, token) {
            return new Promise((resolve, reject) => {
                commit('storeAccessToken', token);
                localStorage.setItem('accessToken', token);
                Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
                resolve()
            })
        },

        storePermission({commit}, permissions) {
            return new Promise((resolve) => {
                commit('storeUserPermissions', permissions);
                localStorage.setItem('userPermissions', JSON.stringify(permissions));
                resolve()
            })
        },

        userLogout ({commit}) {
            return new Promise((resolve, reject) => {
                commit('storeAccessToken', null);
                localStorage.clear();
                // localStorage.setItem('accessToken', null);
                Vue.axios.defaults.headers.common['Authorization'] = ''
                resolve()
            })
        },

        // userLogin({dispatch}, loginObject) {            
        //     localStorage.setItem('accessToken', loginObject.accessToken);
        //     localStorage.setItem('user', JSON.stringify(loginObject.user));                        
        //     dispatch('setUserState', loginObject);
        // },
        // setUserState({commit}, loginObject) {
        //     commit('storeAccessToken', loginObject.accessToken);
        //     commit('storeUser', loginObject.user);                        
        // },
        // userLogout ({commit}) {
        //     localStorage.clear();
        //     commit('removeAccessToken');
        //     commit('removeUser');                            
        // },
        // storeUser({commit}, userInfo) {
        //     localStorage.setItem('user', JSON.stringify(userInfo));
        //     commit('storeUser', userInfo);
        // },        
    }
})
