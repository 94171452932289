<template>
   <v-container fluid class="grey lighten-4" style="min-height: calc(100vh - 64px)">
      <v-container fluid class="">

         <div class="d-flex align-center justify-space-between mb-6">
            <v-checkbox
               v-model="checkbox"
               :label="`${checkbox ? 'Link' : 'Unlink'} product with Party Master`"
               hide-details
               class="pt-0 mt-0"
            ></v-checkbox>
            <v-btn color="primary" class="" @click="addPartyMaster" v-if="hasPermission('party-master-product-master-link-attach')">Add New</v-btn>
         </div>
         <v-row  v-for="(field, i) in partyMastersMapping" :key="i">
            <v-col cols="12" sm="4">
               <v-select
                  v-model="field.partyMasterType"
                  :items="items"
                  item-text="text"
                  item-value="val"
                  filled
                  label="Type"
                  dense
                  hide-details
                  @change="(event) => typeUpdated(event, i)"
               ></v-select>
            </v-col>

            <v-col cols="12" sm="4">
               <v-autocomplete
                  v-model="field.partyMasterSelectedValues"
                  :loading="field.partyMasterloading"
                  :items="field.partyMasterSearchItems"
                  item-text="name"
                  item-value="id"
                  filled
                  clearable
                  :search-input.sync="field.search"
                  @keydown="(event) => getPartyMasterList(event, i)"
                  dense
                  label="Party Master"
                  hide-details
                  hide-no-data
                  :disabled="!field.partyMasterType"
               ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="4">
               <v-autocomplete
                  v-model="field.productsSelectValues"
                  :loading="field.productsLoading"
                  :items="field.productsSearchItems"
                  :item-text="field.partyMasterType == 'wholesaler' ? 'product_name' : 'molecule.name'"
                  hide-selected
                  item-value="id"
                  filled
                  :search-input.sync="field.productsSearch"
                  dense
                  chips
                  small-chips
                  label="Products"
                  @keydown="(event) => getProducts(event, i)"
                  deletable-chips
                  hide-details
                  multiple
                  hide-no-data
                  :disabled="!field.partyMasterSelectedValues"
               ></v-autocomplete>
            </v-col>
         </v-row>
         <v-btn color="primary" class="mt-6"  v-if="hasPermission('party-master-product-master-link-attach')" @click="submitProducts" :disabled="isBtnDisabled() || subBtn.loading" :loading="subBtn.loading">Submit</v-btn>

      </v-container>
   </v-container>
</template>

<script>
  export default {
    data: () => ({
      partyMastersMapping : [],
      checkbox: true,
      items: [
         {text: 'Manufacturer', val: 'manufacturer'},
         {text: 'Wholesaler', val: 'wholesaler'}
      ],
      subBtn: {
         loading:false,
      },
    }),

    methods: {

      typeUpdated(e, i) {
         let obj = this.partyMastersMapping[i]
         obj.partyMasterSearchItems = []
         obj.partyMasterSelectedValues = null
         obj.productsSearchItems = []
         obj.productsSelectValues = []
         Vue.set(this.partyMastersMapping, i, obj)
      },

      addPartyMaster() {
         this.partyMastersMapping.push({
            partyMasterloading:false,
            partyMasterSearchItems: [],
            partyMasterSelectedValues: null,
            search:"",
            productsLoading: false,
            productsSearchItems : [],
            productsSelectValues : [],
            productsSearch:''
         })
      },

      getPartyMasterList(e,i) {
         let obj = this.partyMastersMapping[i]
         obj.partyMasterSearchItems = []
         obj.partyMasterloading = true
         Vue.set(this.partyMastersMapping, i, obj)
         Vue.axios.post(`/api/party-master-list`,{
            search:obj.search,
            type: obj.partyMasterType
         })
            .then((res) => {
               const partyMasterList = res.data.data.data
               obj.partyMasterSearchItems = partyMasterList
               Vue.set(this.partyMastersMapping, i, obj)
               
            })
            .catch((err) => {
                console.log(err)
            }).finally(()=>{
               
               obj.partyMasterloading = false
               Vue.set(this.partyMastersMapping, i, obj)
            })
      },

      getProducts(e,i) {
         

         let obj = this.partyMastersMapping[i]
      
         obj.productsLoading = true
         Vue.set(this.partyMastersMapping, i, obj)
         let req =  {
            // search: obj.productsSearch
         }

         if(obj.partyMasterType === 'manufacturer') {
            
            req.molecule = obj.productsSearch
            req.type ='manufacturer'
         } else {
            req.search = obj.productsSearch
            req.type = 'marketing'
         }

         Vue.axios.post(`/api/product-master-list`, req)
            .then((res) => {
               const productsList = res.data.data.data
               
               obj.productsSearchItems = obj.productsSearchItems.concat(productsList)
            
               Vue.set(this.partyMastersMapping, i, obj)
            })
            .catch((err) => {
                console.log(err)
            }).finally(()=>{
              
               obj.productsLoading = false
               Vue.set(this.partyMastersMapping, i, obj)
            })
      },

      submitProducts() {
         // console.log(this.partyMastersMapping);
         this.subBtn.loading = true;
         let url = ''
           
         this.checkbox ? url = "api/party-master/product-master/add" : url = "api/party-master/product-master/remove"

         var reqObj = []

         this.partyMastersMapping.forEach((obj) => {
          
            let newObj = {
               "party_master_id": obj.partyMasterSelectedValues,
               "product_master_id": obj.productsSelectValues
            }

            reqObj.push(newObj)
         })

         // console.log(reqObj);

         Vue.axios.post(url, reqObj)
            .then((res) => {
               // console.log(res);
               this.$store.commit('showSnackbar', {message: res.data.message})
               this.$router.push('/dashboard');
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
               this.subBtn.loading = false;
            })
      },
      isBtnDisabled() {
         let isDisabled = false

         // return !(this.partyMastersMapping.partyMasterType && this.partyMastersMapping.partyMasterSelectedValues && this.partyMastersMapping.productsSelectValues)
         for(let i = 0; i < this.partyMastersMapping.length;i++) {
            if(!this.partyMastersMapping[i].partyMasterType || !this.partyMastersMapping[i].partyMasterSelectedValues || !this.partyMastersMapping[i].productsSelectValues.length) {
               isDisabled = true
               break
            }
            
         }
         return isDisabled
      }
      
    },

    mounted() {
      this.addPartyMaster()
    },

    computed: {
      
    }

   
  }
</script>

