<template>
    <span>        
        
            <v-chip
                color="light-green"
                label
                pill
                v-if="status == 0"
                text-color="white"
            >
                Pending
            </v-chip>
            <v-chip
                color="green"
                label
                pill
                v-if="status == 1"
                text-color="white"
            >
                Success
            </v-chip>
            <v-chip
                color="red"
                label
                pill
                v-if="status == 2"
                text-color="white"
            >
                Fail
            </v-chip>
            <v-chip
                color="light-green"
                label
                pill
                v-if="status == 3"
                text-color="white"
            >
                Refund Initiated
            </v-chip>
            <v-chip
                color="green"
                label
                pill
                v-if="status == 4"
                text-color="white"
            >
                Refund Successful
            </v-chip>
            <v-chip
                color="red"
                label
                pill
                v-if="status == 5"
                text-color="white"
            >
                Refund Failed
            </v-chip>
    </span>
</template>

<script>
    export default {
        name: "OrderStatus",
        props: ['orderDetails','refundDetails'],
        data() {
            return {
                status:-1
                
            };
        },
        mounted() {
            if(this.orderDetails)
                this.status = this.orderDetails.status
            else if(this.refundDetails){
                switch(this.refundDetails.status){
                    case 0: this.status = 3; break;
                    case 1: this.status = 4; break;
                    case 2: this.status = 5; break;
                }                
            }
                
        }
    }
</script>
