window.Vue = require('vue').default;

import App from './App.vue'
import axios from './axios.js'
import router from './routes'
import VueAxios from "vue-axios"
import VueRouter from 'vue-router'
import vuetify from './vuetify'
import store from './vuex/store.js';
import * as dateutils from './utils/DateTimeUtils.js'
import VueTelInputVuetify from 'vue-tel-input-vuetify';
require('./style.css')

import { ValidationProvider, ValidationObserver, setInteractionMode, extend } from 'vee-validate';
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
setInteractionMode('eager');

import * as rules from 'vee-validate/dist/rules';

Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});
extend('required', {
    message: 'field is required'
})
extend('length', {
    message: '{_field_} must be {length} characters long'
})
extend('min', {
    message: '{_field_} must be at least {length} characters long'
})
extend('max', {
    message: '{_field_} must be at most {length} characters long'
})
extend('size', {
    message: '{_field_} must be less than {size} kb'
})


initAxios()

Vue.use(VueTelInputVuetify, {
    vuetify,
});

Vue.use(VueRouter);

Vue.use(VueAxios, axios);
Vue.prototype.dateutils = dateutils;

Vue.mixin({
    methods: {       
        getImagePath(url){
            var apiUrl = process.env.VUE_APP_API_URL            
            if (url.startsWith("http") || url.startsWith("https")) {
                return url;
            } else return apiUrl +'/'+url
        }, 
        getAssetPath(asset_path){            
            var link = process.env.VUE_APP_BASE_URL
            return link +'/'+asset_path
        },    
        hasPermission(permission) {
            return store.getters.permissions.includes(permission)
        }       
    }
});

const app = new Vue({
    el: '#app',
    router,
    vuetify,
    store,    
    render: h => h(App),
});

function initAxios(){

    axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
    axios.defaults.headers.common['Authorization'] = 'Bearer ' +  localStorage.getItem('accessToken')
    axios.interceptors.response.use(null, function(error) {
        switch(error.response.status) {
            case 401:
                store.dispatch('userLogout').then(() => {
                    var base_url = window.location.origin
                    var urlObj = new URL(base_url)                  
                    window.location.replace(urlObj.href)
                })
                break

            case 403:
                store.commit('showSnackbar', {message: 'Unauthorised Access'})
                break

            case 422:
                for(let i in error.response.data.errors) {
                    var err = error.response.data.errors[i]
                    store.commit('showSnackbar', {message: err.message})
                }
                break
        }
        return Promise.reject(error)
    })
  
  }