<template>
    <v-container fluid class="grey lighten-4" style="min-height: 100vh">
        <ValidationObserver v-slot="{ invalid, handleSubmit }">
        <v-form id="createMediaForm" @submit.prevent="handleSubmit(submitData)">
            <v-container fluid>
                <v-row>
                    <v-col cols="12" sm="12" md="8" class="pb-0">
                        <ValidationProvider name="title" rules="required" v-slot="{ errors }">
                        <v-text-field                    
                            name="title"
                            v-model="dataObj.title"                                                
                            label="Title"
                            outlined
                            dense
                            required
                            :error-messages="errors"
                        ></v-text-field>
                        </ValidationProvider>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" md="4" class="pb-0">
                            <ValidationProvider name="author" rules="required" v-slot="{ errors }">
                            <v-text-field                    
                                name="author"
                                v-model="dataObj.author"                                                
                                label="Author"
                                outlined
                                dense
                                required
                                :error-messages="errors"
                            ></v-text-field>
                            </ValidationProvider>
                        </v-col>
                    <v-col sm="12" md="4">
                       
                        <v-menu                        
                            dense                                                
                            :return-value.sync="dataObj.date"
                            v-model="dateMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            ref="menu"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <ValidationProvider name="date" rules="required" v-slot="{ errors }">
                                <v-text-field
                                    placeholder=""
                                    label="Date"
                                    outlined
                                    v-model="dataObj.date"
                                    clearable
                                    readonly
                                    v-bind="attrs"
                                    name="date"
                                    flat
                                    hide-details
                                    dense
                                    v-on="on"
                                    :error-messages="errors"
                                ></v-text-field>
                                </ValidationProvider>
                            </template>
                            <v-date-picker v-model="dataObj.date" no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="dateMenu = false">Cancel</v-btn>
                                <v-btn text color="primary" @click="$refs.menu.save(dataObj.date)">OK</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="12" md="8" class="pb-0">
                        <ValidationProvider name="link" rules="required" v-slot="{ errors }">
                        <v-text-field                    
                            name="article_link"
                            v-model="dataObj.article_link"                                                
                            label="Article Link"
                            outlined
                            dense
                            required
                            :error-messages="errors"
                        ></v-text-field>
                        </ValidationProvider>
                    </v-col>
                </v-row>
                <v-row align="end" justify="space-between">
                    <ValidationProvider name="img" :rules="!$route.meta.isUpdate ? 'required' :''" v-slot="{ errors,validate }"> 
                    <div class="text-center pa-0 mx-4 image-wrapper">
                        <img v-if="tempImage"
                            width="100%"
                            height="120"
                            id="uploadedImage"
                            style="object-fit:contain"
                        />
                        <img v-else-if="!!(dataObj && dataObj.img)"
                            :src="getImagePath(dataObj.img)"
                            width="100%"
                            height="120"
                            style="object-fit:contain;background:#989898;"
                        />
                        <img v-else
                            class="mx-auto"
                            width="100"
                            height="100"
                            style="margin-top: 12px; margin-bottom: 12px; border:0px;"
                            
                        >
                        
                        <div class="pa-0">
                            
                                <label class="file-upload">
                                    
                                    <input
                                        accept="image/png, image/jpeg"
                                        type="file"
                                        name="img"
                                        id="uploadImageInput"
                                        @change="profileImageUpdate() || ( !$route.meta.isUpdate && validate($event))"                                        
                                        class="mr-4"
                                    />
                                    Upload Image
                                </label>
                                
                            
                            
                        </div>
                        
                        
                    </div>
                    <span v-if="errors.length" style="display:block;" class="ml-4">
                        {{errors[0]}}
                    </span>
                    <span style="font-size:12px;" class="ml-4">
                        Note: Image size should be less than 50Kb
                    </span>
                    </ValidationProvider>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" md="8" class="mt-2">
                        <v-btn type="submit" class="primary"
                            :loading="submitLoading"
                            :disabled="submitLoading"
                        >
                            <template v-if="$route.meta.isUpdate"> Update </template>
                            <template v-else> Create </template>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>                
        </v-form>
        </ValidationObserver>
    </v-container>
</template>

<script>
    import {mapGetters} from 'vuex'    

    export default {
        name: "CreateMedia",
        computed: {
            ...mapGetters([
                'userInfo'
            ])
        },
        data() {
            return {                
                dateMenu:false,
                dataObj:{
                    title:"",
                    author:"",
                    article_link:"",
                    date:""
                },
                tempImage:"",
                base64Profile:"",
                allCategories:[],
                uiCategories:[],
                uiSubCategories:[],
                pageLoading:false,
                submitLoading:false,                

            }
        },
         watch: {        
                
        },
        
        mounted(){
            
            if(this.$route.meta.isUpdate){
                this.getDetailsApi()
            }
            
        },
        methods:{
            getDetailsApi(){
                Vue.axios.get(`/api/admin/media/${this.$route.params.id}`)
                    .then((response) => {

                        if(response.data.status == 1){
                            var obj = response.data.data                                                                                    
                            this.dataObj = obj                            
                        }                            
                                                                                
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                    .finally(() => {
                        this.submitLoading = false
                    })
            },
            inputOnChange(validate,event){
                this.profileImageUpdate()
                    if(!$route.meta.isUpdate)
                        validate(event)
            },
            profileImageUpdate() {
                
                let uploadedFile = document.getElementById('uploadImageInput')
                if (uploadedFile.files && uploadedFile.files[0]) {
                    this.tempImage = true
                    let reader = new FileReader()
                    reader.onload = function (e) {
                        document.getElementById('uploadedImage')
                            .setAttribute('src', e.target.result)
                    }
                    reader.readAsDataURL(uploadedFile.files[0])
                }
            },
            submitData(){
                this.submitLoading = true
                

                var url = ""
                var dataId=""
                if(this.$route.meta.isUpdate){
                    dataId = this.$route.params.id
                    url = "/api/admin/media/"+dataId                    
                }                    
                else
                    url = "/api/admin/media"

                let form = document.getElementById("createMediaForm")
                let formData = new FormData(form)

                Vue.axios.post(url,formData)
                    .then((response) => {

                        if(response.data.status == 1)
                            this.$router.replace({path:"/media"})
                        else if(response.data.status == 0){
                            this.$store.commit('showSnackbar', {message: response.data.message})
                        }
                                                                                
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                    .finally(() => {
                        this.submitLoading = false
                    })
            },           
        }
          
    }
</script>

<style scoped>
    input[type="file"] {
        display: none;
    }
    .file-upload {
        color: #000000;
        display: inline-block;
        padding: 4px 8px;
        cursor: pointer;
        width: 100% !important;
        border-top: 1px solid #000000;
        border-radius: 0 0 4px 4px;
        font-size: 14px;
        background-color: white;
    }
    .image-wrapper {
        border: 1px solid black;
        border-radius: 4px;
        width: 140px;
        height: auto;
        /*min-height: 120px;*/
        background-color: #F6F3F1;
    }
    .image-wrapper img {
        display: block;
    }
</style>
