<template>
    
            
                
                    <v-row>                                 
                                                                                            
                        
                        <v-col
                            cols="12"
                            md="3"
                            class=""
                        >
                            <span class="overline">Refund Code</span><br>
                            <span class="subtitle-1 black--text">{{ refundDetails.pg_response_code }}</span>                                        
                        </v-col>
                        
                        <v-col
                            cols="12"
                            md="3"
                            class=""
                        >
                            <span class="overline">Initiated By</span><br>
                            <span class="subtitle-1 black--text">{{ refundDetails.initiated_by }}</span>                                        
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"                                    
                            class=""
                            v-if="refundDetails"
                        >
                            <span class="overline">Reason</span><br>
                                <span v-if="refundDetails.reason" class="subtitle-1 black--text">{{ refundDetails.reason }}</span>
                                <span v-else class="subtitle-1 black--text">-</span>
                            
                        </v-col>
                    </v-row>
      
</template>

<script>
    export default {
        name: "RefundComponent",
        props: ['refundDetails'],
        mounted() {
            
        }
    }
</script>
