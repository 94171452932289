<template>
    <v-container
        fluid
        class="grey lighten-4"
        style="min-height: calc(100vh - 64px)"
    >
         <div style="height:90vh;" class="d-flex justify-center align-center" v-if="pageLoading">
            <v-progress-circular
                :size="40"
                :width="3"
                color="purple"
                indeterminate
            ></v-progress-circular>
        </div>
        <v-container fluid v-else>
            
            <v-row>
                <v-col cols="12">
                    <Order :orderDetails="orderDetails" 
                        @get_data="getData"
                    />
                </v-col>
            </v-row>

            <v-row>
                <v-col cols=12 class="">
                    <v-card class="rounded-0">
                        <v-card-title>Refunds</v-card-title>     
                        <v-card-text class="text-center" v-if="orderDetails.refunds.length == 0">
                            No Refunds
                        </v-card-text>                                                         
                    </v-card>
                    <v-expansion-panels
                    accordion
                        v-model="panel"                        
                        :readonly="readonly"
                        multiple
                        >
                        <template v-for="(refundDetails, i) in orderDetails.refunds" >
                            <v-expansion-panel :key="i" class="rounded-0">
                                
                                <v-expansion-panel-header >
                                    <v-row>                                                                                                                 
                                        <v-col
                                            cols="12"
                                            md="3"
                                            class=""
                                        >
                                            <span class="overline">Reference Id</span><br>
                                            <span v-if="refundDetails.id" class="subtitle-1 black--text">{{ refundDetails.id }}</span>
                                            <span v-else class="subtitle-1 black--text">-</span>
                                        </v-col>                                    
                                        <v-col
                                            cols="12"
                                            md="3"
                                            class=""
                                        >
                                            <span class="overline">Refund Message</span><br>
                                            <span class="subtitle-1 black--text">{{ refundDetails.pg_response_message }}</span>                                        
                                        </v-col>   
                                        
                                        <v-col
                                            cols="12"
                                            md="3"                                            
                                            class=""
                                        >
                                            <span class="overline">Refund Date/Time</span><br>
                                            <span class="subtitle-1 black--text">{{dateutils.toDisplay(refundDetails.created_at)}}</span>
                                        </v-col>  
                                        <v-col
                                            cols="12"
                                            md="3"
                                            class=""
                                        >
                                            <span class="overline">Status</span><br>
                                            <span class="subtitle-1 black--text"><order-status :refundDetails="refundDetails" /></span>                                        
                                        </v-col>                              
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="px-0 mx-0">
                                    <refund-component :refundDetails="refundDetails" />
                                </v-expansion-panel-content>                                
                            </v-expansion-panel>
                            
                        </template>
                    </v-expansion-panels>
                </v-col>
            </v-row>

            <v-row v-if="refundDetails">
                <v-col cols="12">
                    <refund-component :refundDetails="refundDetails" />
                </v-col>
                
            </v-row>
                        
            <v-row>
                <v-col cols="12">
                    <user-component :userDetails="userDetails" />
                </v-col>
                
            </v-row>
        </v-container>
            
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import OrderStatus from "../../components/OrderStatus.vue";
import Order from "../../components/Order.vue";
import UserComponent from '../../components/UserComponent.vue';
import RefundComponent from '../../components/RefundComponent.vue';

export default {
    computed: {
        ...mapGetters(["accessToken"]),
    },
    components:{
        OrderStatus,
        Order,
        UserComponent,
        RefundComponent
    },
    name: "PreBookingDetails",
    mounted() {
        this.getData();
    },
    methods: {
        
        getData() {
            this.pageLoading = true
            Vue.axios
                .get(`/api/admin/orders/${this.$route.params.id}`)
                .then((response) => {
                    if (response.data.status == 1) {
                        this.orderDetails = response.data.data;
                        this.refundDetails = response.data.data.refund;
                        this.userDetails = response.data.data.user

                        this.refundList = response.data.data.refunds
                        this.totalData = this.refundList.length
                        

                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.pageLoading = false
                });
        },
    },
    data() {
        return {
            panel:[],
            
            fundList:[],
            options: {},
            pageLoading:false,
            orderDetails: {},
            refundDetails:{},
            userDetails:{}
            
        };
    },

    watch: {},
};
</script>
