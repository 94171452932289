<template>
   <v-container fluid class="grey lighten-4" style="min-height: calc(100vh - 64px)">
      <div style="height:90vh;" class="d-flex justify-center align-center" v-if="pageLoading">
         <v-progress-circular
            indeterminate
            color="primary"
         ></v-progress-circular>
      </div>

      <v-container fluid v-else>
         <h2 class="mb-5">Product Master</h2>
         <v-card elevation="0" class="mb-6">
            <h4 class="px-4 py-3"> Details </h4>
            <v-row class="px-4">
               <v-col cols="12" md="6" lg="4">
                  <h6 class="font-weight-bold">Product Name</h6>
                  <p class="mb-0" >{{this.productMaster.product_name ? this.productMaster.product_name : '-'}}</p>
               </v-col>
               <v-col cols="12" md="6" lg="4">
                  <h6 class="font-weight-bold">Type</h6>
                  <p class="mb-0">{{this.productMaster.type ? this.productMaster.type : '-'}}</p>
               </v-col>
               <v-col cols="12" md="6" lg="4">
                  <h6 class="font-weight-bold">Molecule</h6>
                  <!-- <p class="mb-0">{{this.productMaster.molecule.name ? this.productMaster.molecule.name : '-'}}</p> -->
                  <p class="mb-0" v-if="this.productMaster.molecule">{{ this.productMaster.molecule.name}}</p>
                  <p class="mb-0" v-else> - </p>
               </v-col>
               <v-col cols="12" md="6" lg="4">
                  <h6 class="font-weight-bold">Strength</h6>
                  <div v-if="this.productMaster.strength.length > 0">
                     <v-chip-group
                        active-class="primary--text"
                        column
                     >
                        <v-chip
                           v-for="(tag, i) in this.productMaster.strength"
                           :key="i"
                        >
                           {{ tag.name }}
                        </v-chip>
                     </v-chip-group>
                  </div>
                  <div v-else> - </div>
                  <!-- <p class="mb-0">{{this.productMaster.strength ? this.productMaster.strength : '-'}}</p> -->
               </v-col>
               <v-col cols="12" md="6" lg="4">
                  <h6 class="font-weight-bold">Dosage Form</h6>
                  <!-- <p class="mb-0">{{this.productMaster.dosage_form ? this.productMaster.dosage_form : '-'}}</p> -->
                  <div v-if="this.productMaster.dosage_form.length > 0">
                     <v-chip-group
                        active-class="primary--text"
                        column
                     >
                        <v-chip
                           v-for="(tag, i) in this.productMaster.dosage_form"
                           :key="i"
                        >
                           {{ tag.name }}
                        </v-chip>
                     </v-chip-group>
                  </div>
                  <div v-else> - </div>
               </v-col>
               <v-col cols="12" md="6" lg="4">
                  <h6 class="font-weight-bold">Category</h6>
                  <!-- <p class="mb-0">{{this.productMaster.category ? this.productMaster.category : '-'}}</p> -->
                  <div v-if="this.productMaster.category.length > 0">
                     <v-chip-group
                        active-class="primary--text"
                        column
                     >
                        <v-chip
                           v-for="(tag, i) in this.productMaster.category"
                           :key="i"
                        >
                           {{ tag.name }}
                        </v-chip>
                     </v-chip-group>
                  </div>
                  <div v-else> - </div>
               </v-col>
               <v-col cols="12" md="6" lg="4">
                  <h6 class="font-weight-bold">Company</h6>
                  <!-- <p class="mb-0">{{this.productMaster.company.name ? this.productMaster.company.name : '-'}}</p> -->
                  <p class="mb-0" v-if="this.productMaster.company">{{ this.productMaster.company.name}}</p>
                  <p class="mb-0" v-else> - </p>
               </v-col>
            </v-row>
         </v-card>

         <v-card elevation="0">
            <h4 class="px-4 py-3"> Party Master </h4>
            <v-data-table
               class="elevation-0"
               hide-default-footer
               :headers="productMasterHeaders"
               :items="products"
            >  
               <template v-slot:[`item.name`]="{ item }">
                  {{ item.name ?  item.name : '-'}}
               </template>

               <template v-slot:[`item.city`]="{ item }">
                  <!-- {{ item.city.name ?  item.city.name : '-'}} -->
                  <div v-if="item.city">{{ item.city.name }}</div>
                  <div v-else> - </div>
               </template>

               <template v-slot:[`item.state`]="{ item }">
                  <!-- {{ item.state.name ?  item.state.name : '-'}} -->
                  <div v-if="item.state">{{ item.state.name }}</div>
                  <div v-else> - </div>
               </template>

               <template v-slot:[`item.country`]="{ item }">
                  {{ item.country ?  item.country : '-'}}
               </template>

               <template v-slot:[`item.action`]="{ item }">
                  <v-btn @click="showPartyDetails(item)">OPEN</v-btn>
               </template>
            </v-data-table>
         </v-card>

      </v-container>

      
   </v-container>
</template>

<script>
  export default {
    data () {
      return {
         pageLoading:false,
        productMaster: {
            product_name: "",
            molecule: "",
            type: '',
            strength: "",
            dosage_form: "",
            company: "",
        },
        productMasterHeaders: [
            {
               text: 'Name',
               align: 'start',
               sortable: false,
               value: 'name',
            },
            { text: 'City', value: 'city' },
            { text: 'State', value: 'state' },
            { text: 'Country', value: 'country' },
            { text: "", sortable: false, value: "action" }
         ],
         products: []
      }
    },

    created () {
      this.loadDetails()
   },

    methods: {
      loadDetails() {
         this.pageLoading = true
         Vue.axios
            .post(`/api/show/product?`,{id: Number(this.$route.params.id)})
            .then((res) => {
               const data = res.data.data
               
               this.productMaster.product_name = data.product_name
               this.productMaster.molecule = data.molecule
               this.productMaster.type = data.type
               this.productMaster.strength = data.product_strength
               this.productMaster.dosage_form = data.product_dosage_form
               this.productMaster.category = data.product_category
               this.productMaster.company = data.company
               
               this.products = data.party_master
            })
            .catch((error) => {
               console.log(error);
            })
            .finally(() => {
               this.pageLoading = false
            });
      },

      showPartyDetails(obj) {
         window.open("/masterDetails/" + obj.id, '_blank', 'noreferrer');
      },
    }
  }
</script>

<style>

</style>