import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
// import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

const opts = {
    theme: {
        themes: {
            light: {
                primary: '#323232',
                secondary: '#424242',
                accent: '#ff7469',
                error: '#b71c1c',
            },
        },
    },
};

export default new Vuetify(opts)
