<template>
   <v-container fluid class="grey lighten-4" style="min-height: calc(100vh - 64px)">
      <div style="height:90vh;" class="d-flex justify-center align-center" v-if="pageLoading">
         <v-progress-circular
            indeterminate
            color="primary"
         ></v-progress-circular>
      </div>

      <v-container fluid v-else>
         <v-row align="center">
            <v-col
               class="d-flex mb-2"
               cols="12"
               sm="6"
               md="4"
            >
            <v-select
               v-model="productMaster.type"
               :items="productMasterType"
               key="val"
               item-text="text" 
               item-value="val" 
               label="Type"
               hide-details
               dense
               outlined
            ></v-select>
            </v-col>
         </v-row>

         <!-- marketing start -->
         <v-form v-model="valid">
            <v-container class="px-0" fluid>
               <h4 class="mb-2">Details</h4>
               <v-row>
                  <v-col
                     cols="12"
                     sm="6"
                     md="4"
                     class=""
                     v-if="productMaster.type === 'marketing'"
                  >
                     <v-text-field
                        label="Product Name"
                        placeholder="Product Name"
                        outlined
                        :rules="reqRules"
                        dense
                        v-model="productMaster.product_name"
                     ></v-text-field>
                  </v-col>
                  <v-col
                     cols="12"
                     sm="6"
                     md="4"
                     class=""
                  >
                     <v-combobox
                        v-model="productMaster.molecule"
                        :items="moleculeItems"
                        hide-selected
                        label="Molecule"
                        @change="onChangeMolecule"
                        item-text="name"
                        item-value="name"
                        :return-object="false"
                        outlined
                        dense
                        small-chips
                        deletable-chips
                     >
                        <template v-slot:no-data>
                        <v-list-item>
                           <v-list-item-content>
                              <v-list-item-title>
                              No results matching. Press <kbd>enter</kbd> to create a new one
                              </v-list-item-title>
                           </v-list-item-content>
                        </v-list-item>
                        </template>
                     </v-combobox>
                     <!-- <v-text-field
                        label="Molecule"
                        placeholder="Molecule"
                        outlined
                        :rules="reqRules"
                        dense
                        v-model="productMaster.molecule"
                     ></v-text-field> -->
                  </v-col>
                  <v-col
                     cols="12"
                     sm="6"
                     md="4"
                     class=""
                  >
                     <v-combobox
                        v-model="productMaster.strength"
                        :items="strengthItems"
                        hide-selected
                        label="Strength"
                        @change="onChangeStrength"
                        multiple
                        item-text="name"
                        item-value="id"
                        outlined
                        dense
                        small-chips
                        deletable-chips
                     >
                        <template v-slot:no-data>
                        <v-list-item>
                           <v-list-item-content>
                              <v-list-item-title>
                              No results matching. Press <kbd>enter</kbd> to create a new one
                              </v-list-item-title>
                           </v-list-item-content>
                        </v-list-item>
                        </template>
                     </v-combobox>
                     
                  </v-col>
                  <v-col
                     cols="12"
                     sm="6"
                     md="4"
                     class=""
                  >
                     <v-combobox
                        v-model="productMaster.dosage_form"
                        :items="dosageFormItems"
                        hide-selected
                        label="Dosage Form"
                        @change="onChangeDosageForm"
                        multiple
                        item-text="name"
                        item-value="id"
                        outlined
                        dense
                        small-chips
                        deletable-chips
                     >
                        <template v-slot:no-data>
                        <v-list-item>
                           <v-list-item-content>
                              <v-list-item-title>
                              No results matching. Press <kbd>enter</kbd> to create a new one
                              </v-list-item-title>
                           </v-list-item-content>
                        </v-list-item>
                        </template>
                     </v-combobox>
                     
                  </v-col>
                  <v-col
                     cols="12"
                     sm="6"
                     md="4"
                     class=""
                     v-if="productMaster.type === 'marketing'"
                  >
                     <!-- <v-text-field
                        label="Company"
                        placeholder="Company"
                        :rules="reqRules"
                        outlined
                        dense
                        v-model="productMaster.company"
                     ></v-text-field> -->
                     <v-combobox
                        v-model="productMaster.company"
                        :items="companyItems"
                        hide-selected
                        label="Company"
                        @change="onChangeCompany"
                        :return-object="false"
                        item-text="name"
                        item-value="name"
                        outlined
                        dense
                        small-chips
                        deletable-chips
                     >
                        <template v-slot:no-data>
                        <v-list-item>
                           <v-list-item-content>
                              <v-list-item-title>
                              No results matching. Press <kbd>enter</kbd> to create a new one
                              </v-list-item-title>
                           </v-list-item-content>
                        </v-list-item>
                        </template>
                     </v-combobox>
                  </v-col>
                  <v-col
                     cols="12"
                     sm="6"
                     md="4"
                     class=""
                     v-if="productMaster.type === 'manufacturer'"
                  >
                     <v-combobox
                        v-model="productMaster.category"
                        :items="categoryItems"
                        hide-selected
                        label="Category"
                        @change="onChangeCategory"
                        multiple
                        item-text="name"
                        item-value="id"
                        outlined
                        dense
                        small-chips
                        deletable-chips
                     >
                        <template v-slot:no-data>
                        <v-list-item>
                           <v-list-item-content>
                              <v-list-item-title>
                              No results matching. Press <kbd>enter</kbd> to create a new one
                              </v-list-item-title>
                           </v-list-item-content>
                        </v-list-item>
                        </template>
                     </v-combobox>
                     
                  </v-col>
                  
               </v-row>

               <v-btn class="mt-6" color="primary" @click="createProductMaster" :disabled="subBtn.loading || !valid" :loading="subBtn.loading">Submit</v-btn>
               
            </v-container>
         </v-form>
         <!-- marketing end -->

      </v-container>
   </v-container>
</template>

<script>

  export default {
    data: () => ({
      valid: true,
      productMasterType: [
         { text: 'Marketing', val: 'marketing' },
         { text: 'Mfg', val: 'manufacturer' },
      ],

      pageLoading:false,

      subBtn: {
         loading:false,
      },

      strengthItems: [],
      moleculeItems: [],
      dosageFormItems: [],
      categoryItems: [],
      companyItems: [],

      productMaster: {
         type: 'marketing',
         product_name: '',
         molecule: "",
         strength: [],
         dosage_form: [],
         company: "",
         category: [],
      },

      pageLoading:false,
      reqRules: [
        v => !!v || 'This field is required',
      ],
      
    }),

    mounted(){
      if(this.$route.meta.isUpdate){
            this.getProductMasterDetails()
      }

      this.getMolecule()
      this.getStrength()
      this.getDosageForm()
      this.getCategory()
      this.getCompany()
   },


    methods: {
      createProductMaster(){
         this.subBtn.loading = true;
         var requestObj = this.productMaster
         var url = ''
         // console.log(this.productMaster);
         if(this.productMaster.type == 'manufacturer'){
            requestObj.product_name = ''
            requestObj.company = ''
         } else {
            requestObj.category = []
         }

         if(this.$route.meta.isUpdate){
            requestObj.id = this.$route.params.id
            url = "/api/update-product-master"                  
         }                    
         else
            url = "/api/insert-product-master"
         
         Vue.axios.post(url, requestObj)
            .then((res) => {
               // console.log(res);d
               this.$store.commit('showSnackbar', {message: res.data.message})
               this.$router.push('/productMaster');
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
               this.subBtn.loading = false;
            })
      },

      getMolecule() {
         Vue.axios.post(`/api/search`, {
            modal : "Molecule"
         })
         .then((res) => {
            const data = res.data.data
            this.moleculeItems = data
         })
      },

      getCompany() {
         Vue.axios.post(`/api/search`, {
            modal : "Company"
         })
         .then((res) => {
            const data = res.data.data
            this.companyItems = data
         })
      },

      getStrength() {
         Vue.axios.post(`/api/search`, {
            modal : "ProductStrength"
         })
         .then((res) => {
            const data = res.data.data
            this.strengthItems = data
         })
      },

      getDosageForm() {
         Vue.axios.post(`/api/search`, {
            modal : "ProductDosageForm"
         })
         .then((res) => {
            const data = res.data.data
            this.dosageFormItems = data
         })
      },

      getCategory() {
         Vue.axios.post(`/api/search`, {
            modal : "ProductCategory"
         })
         .then((res) => {
            const data = res.data.data
            this.categoryItems = data
         })
      },

      getProductMasterDetails(){
         this.pageLoading = true
         Vue.axios.post(`/api/show/product`, {id: this.$route.params.id})
            .then((res) => {
               const data = res.data.data

               this.productMaster.type = data.type
               this.productMaster.product_name = data.product_name
               this.productMaster.molecule = data.molecule
               if(data.molecule)
                  this.productMaster.molecule = data.molecule.name
               this.productMaster.strength = data.product_strength
               this.productMaster.dosage_form = data.product_dosage_form
               this.productMaster.company = data.company
               if(data.company)
                  this.productMaster.company = data.company.name
               this.productMaster.category = data.product_category
            })
            .catch((error) => {
               console.log(error)
            })
            .finally(() => {
               this.pageLoading = false
            })
      },

      onChangeMolecule(entries) {
         // for (let i = 0; i < entries?.length; i++){
         //    let entry = entries[i]
         //    if(typeof(entry) === 'string' && entry.trim()){
         //       const item = { name:entry}
         //       this.productMaster.molecule[0] = item;
              
         //    }
         // }
         // this.productMaster.molecule = this.productMaster.molecule;
      },

      onChangeCompany(entries) {
         // for (let i = 0; i < entries?.length; i++){
         //    let entry = entries[i]
         //    if(typeof(entry) === 'string' && entry.trim()){
         //       const item = { name:entry}
         //       this.productMaster.company[0] = item;
              
         //    }
         // }
         // this.productMaster.company = this.productMaster.company;
      },

      onChangeStrength(entries) {
         for (let i = 0; i < entries.length; i++){
            let entry = entries[i]
            if(typeof(entry) === 'string' && entry.trim()){
               const item = { name:entry}
               this.productMaster.strength[i] = item;
              
            }
         }
         this.productMaster.strength = this.productMaster.strength;
      },

      onChangeDosageForm(entries) {
         for (let i = 0; i < entries.length; i++){
            let entry = entries[i]
            if(typeof(entry) === 'string' && entry.trim()){
               const item = { name:entry}
               this.productMaster.dosage_form[i] = item;
              
            }
         }
         this.productMaster.dosage_form = this.productMaster.dosage_form;
      },

      onChangeCategory(entries) {
         for (let i = 0; i < entries.length; i++){
            let entry = entries[i]
            if(typeof(entry) === 'string' && entry.trim()){
               const item = { name:entry}
               this.productMaster.category[i] = item;
              
            }
         }
         this.productMaster.category = this.productMaster.category;
      },

    }
  }
</script>

<style>

</style>