<template>
    <v-container fluid class="grey lighten-4" style="min-height: 100vh">
        <v-container fluid>
            <v-row>
                <v-col sm="12" md="4" class="d-flex align-center">
                    <v-text-field
                        placeholder="Search"
                        label="Search"
                        outlined
                        v-model="search"
                        clearable                        
                        flat
                        hide-details
                        dense
                        v-on="on"
                    ></v-text-field>
                </v-col>
               <v-col cols="12" offset-md="4" md="4" align="end">
                    
                    <v-btn                        
                        :disabled="download.loading"
                        :loading="download.loading"
                        color="primary"
                        @click="downloadData()"
                    >
                        Download
                    </v-btn>
                </v-col>

            </v-row>
            <v-row>
             <v-col
                cols="12"
                class="py-2"
            >                
                
                <v-btn-toggle
                    v-model="status"                    
                    borderless
                    mandatory
                    background-color="grey lighten-1"
                    style="overflow-x:auto;"
                    
                >                          
                    <v-btn value="-1" small style="min-width:110px">
                        <span class="">All</span>  
                        <span v-if="status == -1">&nbsp;({{totalData}})</span>
                    </v-btn>                      
                    <v-btn value="1" small style="min-width:110px">
                        <span class="">Ordered</span>  
                        <span v-if="status == 1">&nbsp;({{totalData}})</span>
                    </v-btn>                
                    <v-btn value="0" small style="min-width:110px">
                        <span class="">Not Ordered</span>    
                        <span v-if="status == 0">&nbsp;({{totalData}})</span>
                    </v-btn>                                                                    
                </v-btn-toggle>
            </v-col>
        </v-row>


            <v-row>
                <v-col cols=12>
                    <v-data-table
                        :footer-props="{itemsPerPageOptions:[25,50,100]}"
                        :headers="headers"
                        :items="dataList"
                        :items-per-page="15"
                        :server-items-length="totalData"
                        :loading="loadingData"
                        :options.sync="options"
                        class="elevation-1"
                    >
                        <template v-slot:body="{ items }" v-if="dataList.length">
                            <tbody>
                            <tr v-for="(item, index) in items" :key="item.id">
                                
                                <td>
                                    <router-link tag="a" :to="'/customers/'+item.id">{{ item.first_name +" "+ item.last_name }}</router-link>
                                    
                                </td>                                
                                <td>
                                    {{ item.email }}
                                </td>
                                <td>
                                    {{ item.mobile ? item.mobile : "-" }}
                                </td>
                                <td>
                                    {{ item.signup_source }}
                                </td>
                                <td>
                                    {{ dateutils.toDisplayDate(item.created_at) }}
                                </td>                                                                
                            </tr>
                            </tbody>
                        </template>
                        <template v-slot:loading>
                            Loading...
                        </template>
                        <template v-slot:no-data>
                            No Data found
                        </template>

                    </v-data-table>
                </v-col>
            </v-row>

        </v-container>
    </v-container>
</template>

<script>
    import {mapGetters} from 'vuex';
    export default {
        computed: {
            ...mapGetters([
                'accessToken'
            ])
        },
        name:"Media",
        mounted(){

            this.dates.push(this.dateutils.dayjs().startOf('month').format("YYYY-MM-DD"))
            this.dates.push(this.dateutils.dayjs().format("YYYY-MM-DD"))
            this.search = this.$route.query.search
            this.status = this.$route.query.status

            this.$watch('search', function(value) {
                if(this.searchDelay) {
                    clearTimeout(this.searchDelay)
                }
                clearTimeout(this.searchDelay)
                this.searchDelay = setTimeout(() => {
                    this.$router.replace({path: '/customers', query: { search: value,status:this.status }})
                    this.getDataList()
                }, 1000)
            })

            this.$watch('status', function(value) {                
                this.$router.replace({path: '/customers', query: { status: value, search: this.search }})
                this.getDataList()
            })
        },
        methods: {
            
            gotoEdit(obj){
                this.$router.push({path:"/media/edit/"+obj.id})
            },
            downloadData() {
                this.download.loading = true
                var searchData = this.search
                if(searchData)
                    searchData = searchData.trim()
                Vue.axios({
                    url: '/api/admin/users/export',                    
                    method: 'GET',
                    responseType: 'blob',
                    params:{
                                                      
                            start_date:this.startDate,
                            end_date:this.endDate,                            
                            page:this.options.page,
                            rows:this.options.itemsPerPage,
                            sortBy:this.options.sortBy[0],
                            sortDesc:this.options.sortDesc[0],
                            search:this.search,
                            ordered:this.status
                        }
                })
                .then((response) => {

                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement('a');
                    
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', 'users.xlsx');
                        document.body.appendChild(fileLink);
                    
                        fileLink.click();
                    
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    this.download.loading = false
                })

            },
            getDataList() {
                this.dataList = [];
                this.loadingData = true;
                this.totalData=0
                Vue.axios.get(`/api/admin/users`,
                    {
                        params:{
                            start_date:this.startDate,
                            end_date:this.endDate,                            
                            page:this.options.page,
                            rows:this.options.itemsPerPage,
                            sortBy:this.options.sortBy[0],
                            sortDesc:this.options.sortDesc[0],
                            search:this.search,
                            ordered:this.status
                        }
                    }
                )
                    .then((response) => {

                        var list = [];
                        
                        list = response.data.data.data
                        this.totalData = response.data.data.meta.total

                        list.forEach(element => {
                                element.loading={};
                                element.loading=false;
                            });
                        this.dataList = list
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                    .finally(() => {
                        this.loadingData = false
                    })
            },
        },
            data() {
                return {
                    headers: [
                        // {text: 'img', sortable: false},
                        {text: 'Name', sortable: false},                        
                        {text: 'Email', sortable: false},
                        {text: 'Mobile', sortable: false},
                        {text: 'Signup Source', sortable: false},
                        {text: 'Created At', sortable: false},
                    ],
                    totalData: 0,
                    loadingData: true,
                    dataList:[],
                    options: {},
                    dates:[],
                    startDate:"",
                    endDate:"",
                    dateMenu: false,
                    search:"",
                    status:-1,
                    searchDelay: null,
                    download:{
                        loading:false
                    }
                }
            },

            watch:{                
                
                dates:function(){
                    if(this.dates == null)
                        this.dates = []

                    if(this.dates.length == 2){

                        if(this.dates[0] > this.dates[1]){
                            this.startDate = this.dates[1]
                            this.endDate = this.dates[0]
                        }
                        else{
                            this.startDate = this.dates[0]
                            this.endDate = this.dates[1]
                        }
                    }
                    else{
                        this.startDate = ""
                        this.endDate = ""
                    }
                    if(this.dates.length == 0 || this.dates.length==2){
                        this.getDataList()
                    }
                },
                options: {
                    handler () {
                        this.getDataList()
                    },
                    deep: true,
                }
            }
    }

</script>

<style scoped>

</style>
