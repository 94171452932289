<template>
    <v-card>
        <v-row no-gutters>
            <v-col>
                <v-card-title class="block">
                Order Details
                <span class="ml-8">
                    
                    <order-status :orderDetails="orderDetails" />
                </span>
                </v-card-title>
            
            </v-col>
            <v-col
                cols="12"
                md="4"
                lg="3"
                class="d-flex justify-end align-center mr-16"
                                            
            >                
                <v-btn 
                    color="green darken-1"
                    v-if="orderDetails.status == 1"     
                    small                                         
                    outlined
                    :loading="orderConfirmation.loading"
                    :disabled="orderConfirmation.loading"
                    @click="sendOrderConfirmation()"
                    
                    >Send Order Confirmation</v-btn>

                    <v-btn 
                    color="green darken-1"
                    v-if="showPaymentStatusBtn()"     
                    small                                         
                    outlined
                    :loading="paymentStatus.loading"
                    :disabled="paymentStatus.loading"
                    @click="getPaymentStatus()"
                    
                    >Get Payment Status</v-btn>

                    <v-btn 
                    class="ml-4"
                    color="green darken-1"
                    small                                         
                    outlined                                    
                    v-if="showRefundBtn()"
                    @click="showRefundDialog()"
                    
                    >Refund</v-btn> 

                    
            </v-col>
        </v-row>
        
        
        <v-card-text>
            <v-row>
                <v-col
                    cols="12"
                    md="4"
                    lg="3"
                    class=""
                >
                    <span class="overline">Order ID</span><br>
                    <span class="subtitle-1 black--text">{{
                        orderDetails.number
                    }}</span>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                    lg="3"
                    class=""
                >
                    <span class="overline">Transaction ID</span><br>
                    <span v-if="orderDetails.pg_transaction_id" class="subtitle-1 black--text">{{ orderDetails.pg_transaction_id }}</span>
                    <span v-else class="subtitle-1 black--text">-</span>
                </v-col>
                <v-col
                
                    cols="12"
                    md="4"
                    lg="3"
                    class=""
                >
                    <span class="overline">Amount</span><br>
                    <span class="subtitle-1 black--text">{{ "INR " + orderDetails.amount }}</span>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                    lg="3"
                    class=""
                >
                    <span class="overline">Color update count</span><br>
                    <span class="subtitle-1 black--text">{{
                        orderDetails.color_update_count
                    }}</span>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                    lg="3"
                    class=""
                >
                    <span class="overline">Color</span><br>
                    <span class="subtitle-1 black--text">{{
                        orderDetails.color
                    }}</span>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                    lg="3"
                    class=""
                >
                    <span class="overline"
                        >Payment Method</span
                    ><br>
                    <span class="subtitle-1 black--text">{{orderDetails.payment_method ? orderDetails.payment_method : '-'}}</span>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                    lg="3"
                    class=""
                >
                    <span class="overline">Location</span><br>
                    <span class="subtitle-1 black--text">{{orderDetails.state}} / {{orderDetails.city}} / {{orderDetails.pincode}}</span>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                    lg="3"
                    class=""
                >
                    <span class="overline">Order Date/Time</span><br>
                    <span class="subtitle-1 black--text">{{dateutils.toDisplay(orderDetails.created_at)}}</span>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                    lg="3"
                    class=""
                >
                    <span class="overline">Order Updated At</span><br>
                    <span class="subtitle-1 black--text">{{dateutils.toDisplay(orderDetails.updated_at)}}</span>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                    lg="3"
                    class=""
                >
                    <span class="overline">PG code</span><br>
                    <span v-if="orderDetails.pg_response_code" class="subtitle-1 black--text">{{ orderDetails.pg_response_code }}</span>
                    <span v-else class="subtitle-1 black--text">-</span>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                    lg="3"
                    class=""
                >
                    <span class="overline">PG Message</span><br>
                    <span v-if="orderDetails.pg_response_message" class="subtitle-1 black--text">{{ orderDetails.pg_response_message }}</span>
                    <span v-else class="subtitle-1 black--text">-</span>
                </v-col>
                
            </v-row>
                                        
        </v-card-text>
        <v-dialog v-model="refundObj.show" persistent max-width="400">            
            <v-card>
                <v-card-title class="headline">Refund</v-card-title>
                <v-card-text>
                    
                    <v-row class="mt-1">                            
                        <v-col cols="12" class="mt-0">     
                                <v-textarea
                                    
                                    v-model="refundObj.reason"                                        
                                    label="Reason*"
                                    auto-grow
                                    outlined
                                    rows="2"
                                    
                                >
                                </v-textarea>                               
                                
                            
                            </v-col>
                            <v-col>
                                Are you sure you want to process this refund?
                            </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" v-if="!refundObj.loading" text @click="refundObj.show = false">No</v-btn>
                <v-btn 
                    :disabled="refundObj.loading || !refundObj.reason"
                color="green darken-1" :loading="refundObj.loading" text @click="refundPayment()">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
    import OrderStatus from "./OrderStatus.vue";
    export default {
        name: "Order",
        props: ['orderDetails'],
        components:{
            OrderStatus
        },
        data() {
            return {
                orderConfirmation:{
                    loading:false
                },                
                orderDetails: {},
                paymentStatus:{
                    loading:false
                },
                refundObj:{
                    show:false,
                    loading:false,
                    reason:"",                
                }
            };
        },
        mounted() {
            
        },
        methods:{
            showPaymentStatusBtn(){
                return this.orderDetails.status == 0
            },
            showRefundBtn(){
           
                switch(this.orderDetails.status){
            
                    case 1: return true
                    case 5: return true
                    default:return false
                }
            },
            sendOrderConfirmation(){
            this.orderConfirmation.loading = true
            Vue.axios.get('/api/admin/orders/'+this.orderDetails.id+'/send-confirmation-email')
            .then((response) => {
                
                if(response?.data?.message)
                    this.$store.commit('showSnackbar', {message: response.data.message})
                
            }).catch((error) => {
                console.log(error)
            })
            .finally(() => {
                this.orderConfirmation.loading = false
            })
        },
        getPaymentStatus(){
            this.orderConfirmation.loading = true
            Vue.axios.get('/api/admin/orders/'+this.orderDetails.id+'/payment-status')
            .then((response) => {
                
                if(response.data.status == 1){                        
                    this.$emit('get_data', null)                        
                }                   
                
            }).catch((error) => {
                console.log(error)
            })
            .finally(() => {
                this.paymentStatus.loading = false
            })
        },
       
           
        showRefundDialog(){
            this.refundObj.show = true
            this.refundObj.reason = ""
        },       
         
        refundPayment(){
                this.refundObj.loading = true
                Vue.axios.post('/api/admin/orders/refund',{                    
                    id:this.orderDetails.id,
                    reason:this.refundObj.reason
                }).then((response) => {
                    
                    if(response.data.status == 1){                        
                        this.$emit('get_data', null)    
                        this.refundObj.show = false
                    }   
                    if(response?.data?.message)
                        this.$store.commit('showSnackbar', {message: response.data.message})                 
                    
                }).catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    this.refundObj.loading = false
                })
            },
        }
    }
</script>
