<template>
    <v-container fluid class="grey lighten-4" style="min-height: calc(100vh - 64px)">
        <v-container fluid>
            <v-data-table
                :headers="headers"
                :items="manufacturers"
                class="elevation-0 table"
                style="background-color: transparent;"
                >
                <template v-slot:top>
                    <v-toolbar flat class="mb-6 table-toolbar" style="background-color: transparent;">

                        <v-row>
                            <v-col sm="12" md="4" class="d-flex align-center">
                                <v-text-field
                                placeholder="Search"
                                label="Search"
                                outlined
                                
                                clearable                        
                                flat
                                hide-details
                                dense
                                
                                ></v-text-field>
                            </v-col>
                
                            <v-col cols="12" md="4" class="d-flex align-center">
                                <v-menu
                                    ref="menu"
                                    v-model="dateMenu"
                                    :close-on-content-click="false"
                                    :return-value.sync="date"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="date"
                                        label="Date"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                        dense
                                        hide-details=""
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="date"
                                    no-title
                                    scrollable
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="menu = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.menu.save(date)"
                                    >
                                        OK
                                    </v-btn>
                                    </v-date-picker>
                                </v-menu>
                                </v-col>
                
                                <v-col cols="12" md="4"  class="d-flex align-center justify-end">

                                    <v-dialog
                                        v-model="dialog"
                                        max-width="1000px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="primary"
                                            dark
                                            class="mb-0 me-4"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            New Item
                                        </v-btn>
                                        </template>
                                        <v-card>
                                        <v-card-title>
                                            <span class="text-h5">{{ formTitle }}</span>
                                        </v-card-title>
                            
                                        <v-card-text>
                                            <v-container>
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                                    >
                                                    <v-text-field
                                                        v-model="editedItem.partyName"
                                                        label="Party Name"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                                    >
                                                    <v-text-field
                                                        v-model="editedItem.address"
                                                        label="Address"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                                    >
                                                    <v-text-field
                                                        v-model="editedItem.emails"
                                                        label="Email"
                                                    ></v-text-field>
                                                    
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                                    >
                                                    <v-text-field
                                                        v-model="editedItem.contacts"
                                                        label="Contact"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                                    >
                                                    <v-text-field
                                                        v-model="editedItem.authorises"
                                                        label="Authorises"
                                                    ></v-text-field>
                                                </v-col>
                                                
                                            </v-row>
                                            </v-container>
                                        </v-card-text>
                            
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="close"
                                            >
                                            Cancel
                                            </v-btn>
                                            <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="save"
                                            >
                                            Save
                                            </v-btn>
                                        </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                    <v-dialog v-model="dialogDelete" max-width="500px">
                                        <v-card>
                                        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                                            <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                            <v-spacer></v-spacer>
                                        </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                    
                                    <v-btn color="primary">
                                        Download
                                    </v-btn>
                                </v-col>
                        </v-row>
                    



                    
                    </v-toolbar>
                </template>

                <!-- emails start -->
                <template v-slot:[`item.emails`]="{ item }">
                    <div class="text-center">
                        <v-menu offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="elevation-0"
                            >
                            Emails
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                            v-for="(email, index) in item.emails"
                            :key="index"
                            >
                            <v-list-item-title>{{ email }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                        </v-menu>
                    </div>
                </template>
                <!-- emails end -->

                <!-- contacts start -->
                <template v-slot:[`item.contacts`]="{ item }">
                    <div class="text-center">
                        <v-menu offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="elevation-0"
                            >
                            contacts
                            </v-btn>
                        </template>
                        <v-expansion-panels accordion style="max-width: 300px;">
                            <v-expansion-panel
                                v-for="(contact,i) in item.contacts"
                                :key="i"
                            >
                                <v-expansion-panel-header class="font-weight-bold">{{contact.contactPerson}}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-list-item class="px-0 contact-list-item">
                                     <v-list-item-content class="py-0">
                                         <v-list-item-title class="number-item" v-for="(phoneNum, i) in contact.contactNumbers" :key="i">
                                             {{ phoneNum }}
                                         </v-list-item-title>
                                         
                                     </v-list-item-content>
                                 </v-list-item>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            </v-expansion-panels>
                        </v-menu>
                    </div>
                </template>
                <!-- contacts end -->

                <!-- authorises start -->
                <template v-slot:[`item.authorises`]="{ item }">
                    <div class="text-center">
                        <v-menu offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="elevation-0"
                            >
                            authorises
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                            v-for="(authorise, index) in item.authorises"
                            :key="index"
                            >
                            <v-list-item-title>{{ authorise }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                        </v-menu>
                    </div>
                </template>
                <!-- authorises end -->


                <!-- actions start -->
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                    >
                    mdi-pencil
                    </v-icon>
                    <v-icon
                    small
                    @click="deleteItem(item)"
                    >
                    mdi-delete
                    </v-icon>
                </template>
                <!-- actions end -->
                <template v-slot:no-data>
                    <!-- <v-btn
                    color="primary"
                    @click="initialize"
                    >
                    Reset
                    </v-btn> -->
                    <p class="my-3">No data Available</p>
                </template>
            </v-data-table>
        </v-container>
    </v-container>
</template>

<script>
export default {
  data: () => ({
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    dateMenu: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Party Name',
        align: 'start',
        sortable: false,
        value: 'partyName',
      },
      { text: 'Address', value: 'address' },
      { text: 'Emails', align: 'center', value: 'emails' },
      { text: 'Contacts', align: 'center', value: 'contacts' },
      { text: 'Authorises', align: 'center', value: 'authorises' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    manufacturers: [],
    editedIndex: -1,
    editedItem: {
        partyName: '',
        address: '',
        emails: [],
        contacts: [],
        authorises: [],
    },
    defaultItem: {
        partyName: '',
        address: '',
        emails: [],
        contacts: [],
        authorises: [],
    },
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },

  created () {
    this.initialize()
  },

  methods: {
    initialize () {
      this.manufacturers = [
        // {
        //     partyName: 'ABC Company',
        //     address: 'NH Road 8, Ratnagiri, Maharashtra',
        //     emails: 'everybody@nobody.com',
        //     contacts: '9820982098',
        //     facilities: 'Injectable',
        //     packTypes: 'Amp',
        //     dosageForms: 'Liquid Inj',
        //     categories: 'General',
        //     packings: '1000ml',
        //     certificates: 'GMP',
        // },
        {
            partyName: 'ABC Company',
            address: 'NH Road 8, Ratnagiri, Maharashtra',
            emails: ['everybody@nobody.com', 'someone@mail.com'],
            contacts: [
                {
                    contactPerson: 'Majed',
                    contactNumbers: ['9820982098', '9110911091']
                },
                {
                    contactPerson: 'Mahesh',
                    contactNumbers: ['9819981998']
                },
                {
                    contactPerson: 'Asif',
                    contactNumbers: ['9820098200']
                },
                {
                    contactPerson: 'Manek',
                    contactNumbers: ['9920099200']
                },
            ],
            authorises: ['Cipla', 'Celon', 'Pfizer', 'Gsk'],
            
            
        },

        {
            partyName: 'XYZ Company',
             address: "123, Road,Mumbai Maharashtra",
             emails: ['username@mail.com', 'something@nothing.com'],
             contacts: [
                 {
                     contactPerson: 'Person 1',
                     contactNumbers: ['9876543210', '9876512345']
                 },
                 {
                     contactPerson: 'Person 2',
                     contactNumbers: ['7654321098',]
                 },
             ],
             authorises: ['Cipla', 'Celon', 'Pfizer', 'Gsk'],
        },
        
      ]
    },

    editItem (item) {
      this.editedIndex = this.manufacturers.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.manufacturers.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.manufacturers.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.manufacturers[this.editedIndex], this.editedItem)
      } else {
        this.manufacturers.push(this.editedItem)
      }
      this.close()
    },
  },
}
</script>

<style scoped>
    .v-data-table >>> .v-data-table__wrapper,
    .v-data-table >>> .v-data-footer {
        background-color: #fff;
    }

    .table-toolbar >>> .v-toolbar__content{
        padding-inline: 0;
    }

    .table >>> tr th {
        white-space: nowrap;
    }
    .number-item:not(:last-child){
        margin-bottom: 1rem;
    }

    .v-expansion-panel--active>.v-expansion-panel-header{
        min-height: 3rem;
    }

    .contact-list-item{
        min-height: 1.5rem;
    }
</style>







<!-- <template>
   <v-container fluid class="grey lighten-4" style="min-height: calc(100vh - 64px)">

      
      <v-container fluid>
         <v-row>
            <v-col sm="12" md="4" class="d-flex align-center">
               <v-text-field
                  placeholder="Search"
                  label="Search"
                  outlined
                  
                  clearable                        
                  flat
                  hide-details
                  dense
                  
                ></v-text-field>
            </v-col>

            <v-col sm="12" md="4" class="d-flex align-center">
                    
                    <v-menu                        
                        dense                                                
                        
                        :close-on-content-click="false"
                        :nudge-right="40"
                        ref="menu"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                placeholder="date filter"
                                label="Date"
                                outlined
                                
                                clearable
                                readonly
                                v-bind="attrs"
                                flat
                                hide-details
                                dense
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker  range no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" >Cancel</v-btn>
                            <v-btn text color="primary" >OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>

                <v-col cols="12" md="4" class="d-flex align-center justify-end">
                     <add-wholesaler></add-wholesaler>
                    <v-btn color="primary">
                        Download
                    </v-btn>
                </v-col>
         </v-row>
      </v-container>
      

      <v-container fluid>
         <v-data-table
            :headers="headers"
            :items="wholesalersList"
            :items-per-page="5"
            class="elevation-1"
         >  
            
            <template v-slot:[`item.emails`]="{item}">
                <div class="">
                    <v-menu offset-y :close-on-content-click="false" >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        v-bind="attrs"
                        v-on="on"
                        depressed
                        >
                        emails
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                        v-for="(email, i) in item.emails"
                            :key="i"
                        >
                        <v-list-item-title>{{ email }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                    </v-menu>
                </div>
            </template>
            


            <template  v-slot:[`item.contacts`]="{item}">
                <div class="">
                    <v-menu offset-y :close-on-content-click="false">
                    <template  v-slot:activator="{ on, attrs }">
                        <v-btn
                        v-bind="attrs"
                        v-on="on"
                        depressed
                        >
                        Contacts
                        </v-btn>
                    </template>
                    <v-expansion-panels focusable dense accordion class="condensed" style="max-width: 120px;">
                        <v-expansion-panel
                            v-for="(contact, i) in item.contacts"
                            :key="i"
                            >
                            <v-expansion-panel-header class="max-height: '40px'">{{contact.contactPerson}}</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title v-for="(phoneNum, i) in contact.contactNumbers" :key="i">
                                            {{ phoneNum }}
                                        </v-list-item-title>
                                        
                                    </v-list-item-content>
                                </v-list-item>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    </v-menu>
                </div>
            </template>
            
            

            <template v-slot:[`item.authorities`]="{item}">
                <div class="">
                    <v-menu offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        v-bind="attrs"
                        v-on="on"
                        depressed
                        >
                        authorities
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                        v-for="(authority, i) in item.authorities"
                            :key="i"
                        >
                        <v-list-item-title>{{ authority }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                    </v-menu>
                </div>
            </template>
            

            

        </v-data-table>
         
      </v-container>
   </v-container>
</template>

<script>
    import AddWholesaler from '../../components/AddWholesaler.vue';
  export default {
    components:{
        AddWholesaler
    },
    data () {
      return {
        headers: [
          { text: 'Name', align: 'start', value: 'name'},
          { text: 'Address', value: 'address' },
          { text: 'Email', value: 'emails', sortable: false },
          { text: 'Contacts', value: 'contacts' },
          { text: 'Authorised', value: 'authorities' },
          
        ],
        wholesalersList: [
          {
            name: 'ABC Company',
            address: "NH Road 8, Ratnagiri, Maharashtra",
            emails: ['everybody@nobody.com', 'someone@mail.com'],
            contacts: [
                {
                    contactPerson: 'Majed',
                    contactNumbers: ['9820982098', '9110911091']
                },
                {
                    contactPerson: 'Mahesh',
                    contactNumbers: ['9819981998']
                },
                {
                    contactPerson: 'Asif',
                    contactNumbers: ['9820098200']
                },
                {
                    contactPerson: 'Manek',
                    contactNumbers: ['9920099200']
                },
            ],
            authorities: ['Cipla', 'Celon', 'Pfizer', 'Gsk'],
            
          },
          {
            name: 'XYZ Company',
            address: "123, Road,Mumbai Maharashtra",
            emails: ['username@mail.com', 'something@nothing.com'],
            contacts: [
                {
                    contactPerson: 'Person 1',
                    contactNumbers: ['9876543210', '9876512345']
                },
                {
                    contactPerson: 'Person 2',
                    contactNumbers: ['7654321098',]
                },
            ],
            authorities: ['Cipla', 'Celon', 'Pfizer', 'Gsk'],
            
          },
        
        ],
      }
    },
  }
</script>

<style scoped>
    .v-expansion-panel .v-expansion-panel-header{
        padding: 12px !important;
    }

    .v-expansion-panels.condensed .v-expansion-panel-header {
        padding: 12px !important;
    }
    .v-expansion-panels.condensed
    .v-expansion-panel--active
    .v-expansion-panel-header--active {
        padding: 0 4px 0 12px !important;
        height: 40px !important;
    }

    .v-expansion-panel-content>>> .v-expansion-panel-content__wrap {
        padding: 0 !important;
    }

    .v-expansion-panel-content>>> .v-expansion-panel-content__wrap .v-list-item{
        padding: 0;
        text-align: center;
    }

    .v-expansion-panel-content>>> .v-expansion-panel-content__wrap .v-list-item .v-list-item__content {
        padding: 0;
    }

    .v-expansion-panel-content>>> .v-expansion-panel-content__wrap .v-list-item .v-list-item__content .v-list-item__title{
        padding: 10px ;
    }

    
    
</style> -->