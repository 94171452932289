<template>
    <v-container fluid class="grey lighten-4" style="min-height: 100vh">
        <v-container fluid>
            <v-dialog
                v-model="deleteDialog.show"
                
                :overlay="false"
                max-width="500px"
                transition="dialog-transition"
            >
                <v-card>
                    <v-card-title>
                        Delete Media
                    </v-card-title>
                    <v-card-text>
                        <span class="subtitle-1">Are you sure you want to delete this Media?</span>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="deleteDialog.show = false" :disabled="statusUpdateLoading">No</v-btn>
                        <v-btn @click="deleteFaq()" color="primary" :disabled="deleteDialog.loading" :loading="deleteDialog.loading">Yes</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-row>
                <v-col sm="12" md="4" class="d-flex align-center">
                    <v-text-field
                        placeholder="Search"
                        label="Search Title"
                        outlined
                        v-model="search"
                        clearable                        
                        flat
                        hide-details
                        dense
                        v-on="on"
                    ></v-text-field>
                </v-col>
                <!-- <v-col sm="12" md="4" class="py-0 d-flex align-center">
                    <label v-if="1==0" for="" class="mr-3">Date:</label>
                    <v-menu
                        v-if="1==0"
                        :return-value.sync="dates"
                        v-model="dateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        ref="menu"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                placeholder="date filter"
                                outlined
                                v-model="dates"
                                clearable
                                readonly
                                v-bind="attrs"
                                flat
                                hide-details
                                dense
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="dates" range no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="dateMenu = false">Cancel</v-btn>
                            <v-btn text color="primary" @click="$refs.menu.save(dates)">OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col> -->
                <v-col cols="12" md="8" align="end">
                    <!-- <v-btn
                        class="ma-2"
                        color="primary"
                        @click="getDataList()"
                    >
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn> -->
                    <v-btn                        
                        color="primary"                        
                        @click="$router.push({path: `/media/create`})"
                    >
                        Create
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols=12>
                    <v-data-table
                        :footer-props="{itemsPerPageOptions:[25,50,100]}"
                        :headers="headers"
                        :items="dataList"
                        :items-per-page="15"
                        :server-items-length="totalData"
                        :loading="loadingData"
                        :options.sync="options"
                        class="elevation-1"
                    >
                        <template v-slot:body="{ items }" v-if="dataList.length">
                            <tbody>
                            <tr v-for="(item, index) in items" :key="item.id">
                                
                                <td>
                                    {{ item.title }}
                                </td>
                                <td>
                                    {{ item.author }}
                                </td>
                                <td>
                                    {{ dateutils.toDisplayDate(item.date) }}
                                </td>                                
                                <td>
                                    <v-icon @click="gotoEdit(item)" class="pointer">mdi-pencil</v-icon>
                                    <v-icon @click="deleteItemInit(item)" class="pointer red--text">mdi-delete</v-icon>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                        <template v-slot:loading>
                            Loading...
                        </template>
                        <template v-slot:no-data>
                            No Data found
                        </template>

                    </v-data-table>
                </v-col>
            </v-row>

        </v-container>
    </v-container>
</template>

<script>
    import {mapGetters} from 'vuex';
    export default {
        computed: {
            ...mapGetters([
                'accessToken'
            ])
        },
        name:"Media",
        mounted(){
            // this.dates.push(this.dateutils.dayjs().startOf('month').format("YYYY-MM-DD"))
            // this.dates.push(this.dateutils.dayjs().format("YYYY-MM-DD"))
            this.search = this.$route.query.search

            this.$watch('search', function(value) {
                if(this.searchDelay) {
                    clearTimeout(this.searchDelay)
                }
                var ctx = this
                this.searchDelay = setTimeout(() => {
                    ctx.$router.replace({path: '/media', query: { search: value }})
                    ctx.getDataList()
                }, 1000)
            });
        },
        methods: {
            deleteItemInit(item){
                this.deleteDialog.data = item
                this.deleteDialog.show = true
            },  
            deleteFaq(){
                this.deleteDialog.loading = true;
                var id = this.deleteDialog.data.id
                Vue.axios.get("/api/admin/media/"+id+"/delete")
                    .then((response) => {
                        if(response.data.status == 1){
                            this.getDataList()                        
                            this.deleteDialog.show = false
                        }
                            
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                    .finally(() => {
                        this.deleteDialog.loading = false
                        
                    })
            },
            gotoEdit(obj){
                this.$router.push({path:"/media/edit/"+obj.id})
            },
            download() {
                Vue.axios.get(`/api/admin/leads/download`,
                    {
                        params:{
                            start_date:this.startDate,
                            end_date:this.endDate,                            
                        }
                    }
                )
                    .then((response) => {

                        if(response.data.status == 1){

                            window.open(response.data.data)
                        }

                    })
                    .catch((error) => {
                        console.log(error)
                    })
                    .finally(() => {
                    })

            },
            getDataList() {
                this.dataList = [];
                this.loadingData = true;
                Vue.axios.get(`/api/admin/media`,
                    {
                        params:{
                            start_date:this.startDate,
                            end_date:this.endDate,                            
                            page:this.options.page,
                            rows:this.options.itemsPerPage,
                            sortBy:this.options.sortBy[0],
                            sortDesc:this.options.sortDesc[0],
                            search:this.search
                        }
                    }
                )
                    .then((response) => {

                        var list = [];
                        
                        list = response.data.data.data
                        this.totalData = response.data.data.meta.total

                        list.forEach(element => {
                                element.loading={};
                                element.loading=false;
                            });
                        this.dataList = list
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                    .finally(() => {
                        this.loadingData = false
                    })
            },
        },
            data() {
                return {
                    statusUpdateLoading: false,
                    deleteDialog:{
                        show:false,
                        loading:false,
                        data:null
                    },
                    headers: [
                        // {text: 'img', sortable: false},
                        {text: 'Title', sortable: false},
                        {text: 'Author', sortable: false},
                        {text: 'Date', sortable: false},
                        {text: '', sortable: false},
                    ],
                    totalData: 0,
                    loadingData: true,
                    dataList:[],
                    options: {},
                    dates:[],
                    startDate:"",
                    endDate:"",
                    dateMenu: false,
                    search:"",
                    searchDelay: null
                }
            },

            watch:{
                search:function(val){
                    
                },              
                options: {
                    handler () {
                        this.getDataList()
                    },
                    deep: true,
                }
            }
    }

</script>

<style scoped>

</style>
