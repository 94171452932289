import store from './vuex/store.js'
import VueRouter from 'vue-router'
import AdminLogin from './views/admin/Login.vue';
import PreBookings from './views/admin/PreBookings.vue';
import PreBookingDetails from './views/admin/PreBookingDetails.vue';
import FAQs from './views/admin/FAQs.vue';
import SortFAQs from './views/admin/SortFAQs.vue';
import CreateFAQ from './views/admin/CreateFAQ.vue';
import Media from './views/admin/Media.vue';
import CreateMedia from './views/admin/CreateMedia.vue';
import Users from './views/admin/Users.vue';
import UserDetails from './views/admin/UserDetails.vue';
import Refunds from './views/admin/Refunds.vue';
import RefundDetails from './views/admin/RefundDetails.vue';
import EditOrder from './views/admin/EditOrder.vue';
import PartyMaster from './views/admin/PartyMaster.vue';
import Wholesaler from './views/admin/Wholesaler.vue';
import ProductMaster from './views/admin/ProductMaster.vue';
import AddProduct from './views/admin/AddProduct.vue';
import CreatePartyMaster from './views/admin/CreatePartyMaster.vue';
import CreateProductMaster from './views/admin/CreateProductMaster.vue';
import Dashboard from './views/admin/Dashboard.vue'
import MasterDetails from './views/admin/MasterDetails.vue'
import ProductDetails from './views/admin/ProductDetails.vue'

export const routes = [
    
    {
        name: 'adminLogin',
        path: '/',
        component: AdminLogin,
        meta: {
            title: '',        
            requireLogin: false,    
            loggedInRedirect: "dashboard"
        }
    },
    {
        name: 'EditPartyMaster',
        path: '/partyMaster/edit/:id',        
        component: CreatePartyMaster,
        meta: {
            title: 'Edit PartyMaster',            
            requireLogin: true,
            isUpdate: true
        }
    },
    {
        name: 'EditProductMaster',
        path: '/ProductMaster/edit/:id',        
        component: CreateProductMaster,
        meta: {
            title: 'Edit ProductMaster',            
            requireLogin: true,
            isUpdate: true
        }
    },
    {
        name: 'partyMaster',
        path: '/partyMaster',        
        component: PartyMaster,
        meta: {
            title: 'Party Master',            
            requireLogin: true
        }
    },
    {
        name: 'wholesaler',
        path: '/wholesaler',        
        component: Wholesaler,
        meta: {
            title: 'Wholesaler',            
            requireLogin: true
        }
    },
    {
        name: 'productMaster',
        path: '/productMaster',        
        component: ProductMaster,
        meta: {
            title: 'ProductMaster',            
            requireLogin: true
        }
    },
    {
        name: 'addProduct',
        path: '/addProduct',        
        component: AddProduct,
        meta: {
            title: 'Product Add',            
            requireLogin: true
        }
    },
    {
        name: 'createPartyMaster',
        path: '/createPartyMaster',        
        component: CreatePartyMaster,
        meta: {
            title: 'Create Party Master',            
            requireLogin: true
        }
    },
    {
        name: 'createProductMaster',
        path: '/createProductMaster',        
        component: CreateProductMaster,
        meta: {
            title: 'Create Product Master',            
            requireLogin: true
        }
    },
    {
        name: 'dashboard',
        path: '/dashboard',        
        component: Dashboard,
        meta: {
            title: 'Dashboard',            
            requireLogin: true
        }
    },
    {
        name: 'masterDetails',
        path: '/masterDetails/:id',        
        // path: '/refunds/:id',        
        component: MasterDetails,
        meta: {            
            title: 'Master Details',            
            requireLogin: true
        }
    },
    {
        name: 'productDetails',
        path: '/productDetails/:id',        
        // path: '/refunds/:id',        
        component: ProductDetails,
        meta: {            
            title: 'Product Details',            
            requireLogin: true
        }
    },
    
    // {
    //     name: 'notFound',
    //     path: '*',
    //     component: NotFound,
    //     meta: {
    //         httpError: true
    //     }
    // },
];

// Vue.use(VueRouter);
const router = new VueRouter({
    mode: 'history',
    routes,
    // base:process.env.BASE_URL
});

router.beforeEach(
    (to, from, next) => {
        let accessToken = store.getters.accessToken ? store.getters.accessToken : localStorage.getItem('accessToken')        
        
        if(!to.meta.requireLogin ){            
            if(to.meta.loggedInRedirect  && accessToken){
                next({
                    name: to.meta.loggedInRedirect,                    
                })
            }
            else
                next()
        }
        else {
            if (!accessToken) {
                next({
                    name: 'adminLogin',
                })
            } else {
                if (!store.getters.accessToken) {
                    store.dispatch('storeToken', accessToken)
                    store.dispatch('storePermission', JSON.parse(localStorage.getItem('userPermissions')))
                }
                next()
            }
        }
    }
)

export default router
