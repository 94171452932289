<template>
    <v-container fluid class="grey lighten-4" style="min-height: 100vh">

        <v-row class="d-flex justify-center">
            <v-col cols="12" xs="12" sm="5" md="5" lg="3" class="mt-8">
                <v-form @submit.prevent="login()">
                    <v-card>
                        <v-card-text>
                            <v-col cols="12">
                                <v-row justify-center>
                                    <v-col cols="12">
                                        <v-img :src="getAssetPath('img/logo-dark.jpg')" class="mx-auto" width="100"></v-img>
                                        <h1 class="mt-8 text-center">Amari Trade Alliance</h1>
                                    </v-col>
                                </v-row>
                                <v-text-field
                                    outlined
                                    v-model="email"
                                    class="mt-8 pl-1 pr-1"
                                    type="text"
                                    label="Email*"
                                    placeholder=""
                                ></v-text-field>
                                <v-text-field
                                    type="password"
                                    outlined
                                    v-model="password"
                                    class="mt-1 pl-1 pr-1"
                                    label="Password*"
                                    placeholder=" "
                                ></v-text-field>
                                <div class="text-center">
                                    <v-btn color="primary" class="mx-auto" type="submit" :loading="loading" :disabled="loading">
                                        Login
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>

                        </v-card-text>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
    export default {
        name: "AdminLogin",
        data() {
            return {
                loading: false,
                email: "",
                password: "",
                userPermissions: []
            }
        },
        methods: {
            isValid() {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!this.email.length) {
                    this.$store.commit('showSnackbar', {message: "Enter Email"})
                    return false
                } else if( !re.test(this.email)) {
                    this.$store.commit('showSnackbar', {message: "Email is not valid"})
                    return false
                } else if (!this.password.length) {
                    this.$store.commit('showSnackbar', {message: "Enter password"})
                    return false
                }
                return true
            },

            login() {
                if(!this.isValid()) return
                this.loading = true

                Vue.axios.post('api/login', {
                    email: this.email,
                    password: this.password
                })
                .then((res) => {
                    if(res.data.status) {
                        this.$store.dispatch('storeToken', res.data.token)
                        this.fetchPermissions()
                        this.$store.commit('showSnackbar', {message: res.data.message})
                        this.$router.push({name: 'dashboard'})
                    } else {
                        this.$store.commit('showSnackbar', {message: res.data.message})
                    }
                })
                .finally(() => {
                    this.loading = false;
                })
            },

             fetchPermissions(){
                 Vue.axios.post('api/user-permissions')
                .then((res) => {
                    this.$store.dispatch('storePermission', res.data.permissions)
                    this.userPermissions = res.data.permissions
                })
                .catch((err) => {
                    console.log(err);
                })
            }
        }
    }
</script>
