<template>
    <v-container fluid class="grey lighten-4" style="min-height: 100vh">
       
        <v-container fluid>
            <v-row>

                <v-col cols="12" sm="12" md="4" class="pb-0">
                    
                    <v-select 
                        dense 
                        :items="allCategories" 
                        item-text="name" 
                        item-value="id" 
                        label="Category" 
                        outlined 
                        name="category"                         
                        v-model="faqDetails.category_id"
                        
                    >
                    </v-select>
                    
                </v-col>

                <v-col cols="12" sm="12" md="4" class="pb-0">
                    
                    <v-select 
                        dense 
                        :items="uiSubCategories" 
                        item-text="name" 
                        item-value="id" 
                        label="Sub Category" 
                        outlined 
                        name="sub_category"                         
                        v-model="faqDetails.sub_category_id"
                        
                    >
                    </v-select>
                    
                </v-col>
            </v-row>
            <!-- <v-row>
                 <v-col sm="12" md="4" class=" d-flex align-center">
                    <v-text-field
                        placeholder="Search"
                        label="Search Question"
                        outlined
                        v-model="search"
                        clearable                        
                        flat
                        hide-details
                        dense
                        v-on="on"
                    ></v-text-field>
                </v-col>

                
            </v-row> -->

            <v-row>
                <v-col cols=12>
                    <v-data-table
                        disable-pagination
                        hide-default-footer
                        :footer-props="{itemsPerPageOptions:[-1,2]}"                        
                        :headers="headers"
                        :items="dataList"
                        :items-per-page="2"
                        :server-items-length="totalData"
                        :loading="loadingData"
                        :options.sync="options"
                        class="elevation-1"
                    >
                        <template v-slot:body="{ items }" v-if="dataList.length">
                            <tbody>
                            <tr v-for="(item, index) in items" :key="item.id">                                
                                <td>
                                    {{ item.question }}
                                </td>                                
                                <td>
                                    <div class="flex justify-center align-center">
                                        <v-text-field                    
                                        style="width:120px;"
                                            name="title"
                                            type="number"
                                            v-model="item.order_number"                                                                                            
                                            outlined
                                            dense
                                            required
                                            hide-details="true"
                                            :error-messages="errors"
                                        ></v-text-field>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                        <template v-slot:loading>
                            Loading...
                        </template>
                        <template v-slot:no-data>
                            <template v-if="faqDetails.sub_category_id">
                                No Data found
                            </template>
                            <template v-else>
                                -
                            </template>
                        </template>

                    </v-data-table>
                </v-col>
                
                <v-col cols="12" sm="12" md="8" class="mt-2">
                    <v-btn type="submit" class="primary"
                        @click="submitFaq()"
                        :disabled="submitLoading || (!faqDetails.sub_category_id)"
                        :loading="submitLoading"
                    >
                        <template > Update </template>                        
                    </v-btn>
                </v-col>
                
            </v-row>

        </v-container>
        
    </v-container>
</template>

<script>
    import {mapGetters} from 'vuex';
    export default {
        computed: {
            ...mapGetters([
                'accessToken'
            ])
        },
        name:"SortFAQs",
        mounted(){

            this.getCategories()

            // this.$watch('search', function(value) {
            //     if(this.searchDelay) {
            //         clearTimeout(this.searchDelay)
            //     }
            //     this.searchDelay = setTimeout(() => {
            //         this.$router.replace({path: '/faqs', query: { search: value }})
            //         this.getDataList()
            //     }, 1000)
            // })

        },
        methods: {
            submitFaq(){
                this.submitLoading = true                

                Vue.axios.post("/api/admin/faqs/edit-order",{
                    faqs:this.dataList
                })
                    .then((response) => {

                        if(response.data.status == 1)
                            this.$router.replace({path:"/faqs"})
                        else if(response.data.status == 0){                            
                            this.$store.commit('showSnackbar', {message: response.data.message})
                        }
                        if(response?.data?.message)
                            this.$store.commit('showSnackbar', {message: response.data.message})
                                                                                
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                    .finally(() => {
                        this.submitLoading = false
                    })
            },
            getCategories() {                
                this.pageLoading = true;
                Vue.axios.get(`/api/admin/faqs/categories`)
                    .then((response) => {

                        var list = [];
                        this.allCategories = response.data.data
                        this.uiCategories = response.data.data                                                
                        this.dataList = list
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                    .finally(() => {
                        this.pageLoading = false
                    })
            },
            getDataList() {
                this.dataList = [];
                this.loadingData = true;
                Vue.axios.get(`/api/admin/faqs/edit-order`,
                    {
                        params:{
                            sub_category_id:this.faqDetails.sub_category_id
                        }
                    }
                )
                    .then((response) => {

                        var list = [];
                        
                        list = response.data.data
                        this.totalData = list.length
                       
                        this.dataList = list
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                    .finally(() => {
                        this.loadingData = false
                    })
            },
            updateSubCategoryList(){
                
                var ctx = this
                if(!this.allCategories.length)
                    return
                var cat = this.allCategories.find(function(item){
                    if(item.id == ctx.faqDetails.category_id)
                        return true
                    else
                        return false
                    
                })                
                this.uiSubCategories = cat.subCategories
            },
        },
            data() {
                return {
                    allCategories:[],
                    uiCategories:[],
                    uiSubCategories:[],
                    faqDetails:{
                        category_id:"",
                        sub_category_id:"",
                        question:"",
                        answer:"",
                        order:null
                    },
                    headers: [                        
                        {text: 'Question', sortable: false},                                                
                        {text: 'Order', sortable: false},                                                
                    ],
                    totalData: 0,
                    loadingData: false,
                    dataList:[],
                    options: {},
                    dates:[],
                    startDate:"",
                    endDate:"",
                    dateMenu: false,
                    search:"",
                    searchDelay: null,
                    submitLoading:false
                }
            },

            watch:{
                
                'faqDetails.category_id':{
                    handler: function () {                        
                        this.updateSubCategoryList()
                    },
                    deep: true                      
                },
                'faqDetails.sub_category_id':{
                    handler: function () {     
                        if(this.faqDetails.sub_category_id)                   
                            this.getDataList()
                    },
                    deep: true                      
                }               
            }
    }

</script>

<style scoped>

</style>
