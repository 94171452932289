var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"grey lighten-4",staticStyle:{"min-height":"calc(100vh - 64px)"},attrs:{"fluid":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-0 table",staticStyle:{"background-color":"transparent"},attrs:{"footer-props":{'disable-items-per-page':true},"server-items-length":_vm.totalData,"options":_vm.options,"headers":_vm.headers,"items":_vm.productMasters,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.product_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product_name ? item.product_name : '-')+" ")]}},{key:"item.molecule",fn:function(ref){
var item = ref.item;
return [(item.molecule)?_c('div',[_vm._v(_vm._s(item.molecule.name))]):_c('div',[_vm._v(" - ")])]}},{key:"item.product_strength",fn:function(ref){
var item = ref.item;
return [(item.product_strength.length > 0)?_c('div',[_c('v-chip-group',{attrs:{"active-class":"primary--text","column":""}},_vm._l((item.product_strength),function(tag,i){return _c('v-chip',{key:i,attrs:{"small":""}},[_vm._v(" "+_vm._s(tag.name)+" ")])}),1)],1):_c('div',[_vm._v(" - ")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mb-6 table-toolbar",staticStyle:{"background-color":"transparent"},attrs:{"flat":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"sm":"12","md":"4"}}),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","md":"4"}}),_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"cols":"12","md":"4"}},[_c('v-dialog',{attrs:{"max-width":"500px","transition":"dialog-transition"},model:{value:(_vm.deleteDialog.show),callback:function ($$v) {_vm.$set(_vm.deleteDialog, "show", $$v)},expression:"deleteDialog.show"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 d-block text-center mx-auto"},[_vm._v("Are you sure you want to delete?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.deleteDialog.loading},on:{"click":function($event){_vm.deleteDialog.show = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.deleteDialog.loading,"loading":_vm.deleteDialog.loading},on:{"click":function($event){return _vm.deleteItemConfirm()}}},[_vm._v("Yes")]),_c('v-spacer')],1)],1)],1),(_vm.hasPermission('product-masters-create'))?_c('v-btn',{attrs:{"color":"primary mr-4"},on:{"click":function($event){return _vm.$router.push({path: "/createProductMaster"})}}},[_vm._v(" Create ")]):_vm._e()],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.hasPermission('product-masters-update-owner-temp') || _vm.hasPermission('product-masters-update'))?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.hasPermission('product-masters-delete-owner-temp') || _vm.hasPermission('product-masters-delete'))?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('p',{staticClass:"my-3"},[_vm._v("No data Available")])]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }